import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@chakra-ui/react'
import { SettingsIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { GiTwoCoins, GiCarKey } from "react-icons/gi";
import { AiOutlineHome, AiOutlineArrowLeft, AiOutlineCalendar, AiOutlineTrophy } from "react-icons/ai";
import { RiChargingPile2Fill, RiAccountCircleLine, RiSuitcaseLine, RiHome2Line, RiLeafFill } from "react-icons/ri";
import { BsEye, BsEyeSlash, BsFillCircleFill, BsPerson } from "react-icons/bs"
import { ImArrowRight2 } from "react-icons/im"
import { FiMoreVertical } from "react-icons/fi"
import { MdElectricCar, MdOutlineElectricCar } from 'react-icons/md'

const _supported_item_names = {
  'GiTwoCoins': GiTwoCoins, // for Point Nav item
  'AiOutlineHome': AiOutlineHome, // for Home Nav item
  'RiChargingPile2Fill': RiChargingPile2Fill, // for Charger Nav item
  'RiAccountCircleLine': RiAccountCircleLine, // for Setting Nav item
  'BsEyeSlash': BsEyeSlash, // for pw input
  'BsEye': BsEye, // for pw input
  'AiOutlineArrowLeft': AiOutlineArrowLeft,
  'ImArrowRight2': ImArrowRight2,
  'FiMoreVertical': FiMoreVertical,
  'BsFillCircleFill': BsFillCircleFill,
  'MdElectricCar': MdElectricCar,
  'MdOutlineElectricCar': MdOutlineElectricCar,
  'GiCarKey': GiCarKey,
  'RiSuitcaseLine': RiSuitcaseLine,
  'RiHome2Line': RiHome2Line,
  'BsPerson': BsPerson,
  'AiOutlineCalendar': AiOutlineCalendar,
  'RiLeafFill': RiLeafFill,
  'ChakraSettingsIcon': SettingsIcon,
  'AiOutlineTrophy': AiOutlineTrophy,
  'ChakraArrowForwardIcon': ArrowForwardIcon
};

const SupportedIcon = (props) => {
  const { name } = props;

  // Validate props.name before rendering
  useEffect(() => {
    if (name !== undefined && !_supported_item_names?.[name]) {
      throw Error(`${name} is not supported icon name!`)
    }
  }, [name])

  if (name === undefined) return;

  return (
    <Icon as={_supported_item_names[name]} {...props} />
  );
};

SupportedIcon.propTypes = {
  name: PropTypes.string
};

export default SupportedIcon;