import React, { useEffect, useRef, useState } from 'react';
import {Html5Qrcode} from "html5-qrcode"
import { useNavigate } from 'react-router-dom';

const ScanQR = props => {
  const navigate = useNavigate();
  const html5QrcodeRef = useRef();
  const [decodedQRData, setDecodedQRData] = useState({
    isScanning: false,
    isScanSuccessful: false,
    isScanFailure: false,
    decodedText: null,
    decodedResult: null,
    error: ''
  });

  useEffect(() => {
    if (html5QrcodeRef.current === undefined) startScanning();
    return () => {
      try {
        html5QrcodeRef.current?.stop();
      } catch (e) {
        console.log('cannot stop, cause video is not started/paused')
      }
      html5QrcodeRef.current?.clear();
    }
  }, []);

  const startScanning = () => {
    try {
      if (html5QrcodeRef.current !== undefined) {
        stopScanning();
        html5QrcodeRef.current?.clear();
      }

      html5QrcodeRef.current = new Html5Qrcode("reader");

      // SUCCESS
      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        /* handle success */
        console.log('SCAN SUCCESS');
        console.log('decodedText: ', decodedText)
        console.log('decodedResult: ', decodedResult)

        setDecodedQRData({
          ...decodedQRData,
          isScanSuccess: true,
          isScanning: false,
          decodedText: decodedText, // data
          decodedResult: decodedResult,
          error: ''
        })

        // close after successful scan
        stopScanning();

        // window.open(decodedText)
        qrNavigate(decodedText, true)
      };

      // navigate logic
      const qrNavigate = (decodedText, isLoggedIn) => {
        let decodedURL;
        try {
          decodedURL = new URL(decodedText);
        } catch (error) {
          console.log(error);
          return
        }
        // checks domain
        const myHostname = window.location.hostname;
        // if (decodedURL.hostname !== myHostname) return
        // navigate to the decoded url
        if (isLoggedIn) navigate(`/driverapp/charger/charger-detail${decodedURL.search}`, { replace: true })
        else navigate(`${decodedURL.pathname}${decodedURL.search}`, { replace: true })
      }

      // CAMERA CONFIG
      const config = {
        fps: 30,
        qrbox: { width: 250, height: 250 },
        aspectRatio: 1.777778
      };

      // START SCANNING
      html5QrcodeRef.current.start(
        { facingMode: 'environment' },
        config,
        qrCodeSuccessCallback
      );
    } catch(err) {
      console.error('START SCAN FAILED', err)
      html5QrcodeRef.current = undefined;
      setDecodedQRData({
        ...decodedQRData,
        isScanSuccess: false,
        isScanning: false,
        isScanFailure: true,
        decodedText: null,
        decodedResult: null,
        error: err || "QR Code parsing failed!"
      })
    }
  };

  const stopScanning = () => {
    if (html5QrcodeRef.current) {
      html5QrcodeRef.current
        ?.stop()
        ?.then((ignore) => {
          // QR Code scanning is stopped.
          console.log('Stopped after successful scan')
        })
        ?.catch((err) => {
          // Stop failed, handle it.
          console.log('fails to stop after successfull scan result')
        })
    }
  }

  return (
    <div id='reader' />
  );
};

ScanQR.propTypes = {
  
};

export default ScanQR;