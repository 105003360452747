import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center } from '@chakra-ui/react';
import { NotoSansH2 } from 'components/styled';

const TotalPointsNum = props => {
  const { points } = props;
  return (
    <>
      <Box
        w='100%' backgroundColor='rgba(30 30 30 / 1)'
      >
        <Center w='100%' h='100%'>
          <Box w='83%' marginBottom='3%'>
            <NotoSansH2 fontSize='1.5rem'>{points.toLocaleString('en', {useGrouping:true}) || '0'}</NotoSansH2>
          </Box>
        </Center>
      </Box>
    </>
  );
};

TotalPointsNum.propTypes = {
  points: PropTypes.number
};

export default TotalPointsNum;