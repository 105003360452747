import React from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Box, Image, VStack } from '@chakra-ui/react';
import styled from 'styled-components';
import { bonvoyOrange } from 'util/static/bonvoyStyle';
import { SupportedIcon } from 'components';

const ActiveIconNameP = styled.p`
  font-family: NotoSans;
  font-weight: 300;
  font-size: 0.8rem;
  color: ${bonvoyOrange};
`;
const DimIconNameP = styled.p`
  font-family: NotoSans;
  font-weight: 300;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
`;

const CustomLink = props => {
  const { to, name, iconAs, activeIconSrc, dimIconSrc } = props;
  const navigate = useNavigate();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  const generateIcon = (match, iconAs, activeIconSrc, dimIconSrc) => {
    if (iconAs) {
      let iconColor = 'rgba(255,255,255, 0.5)';
      if (match) iconColor = bonvoyOrange;
      return (
        <Box h={'1rem'}>
          <SupportedIcon name={iconAs} boxSize={'1.5rem'} color={iconColor} />
        </Box>
      )
    }
    else if (activeIconSrc && dimIconSrc) {
      if (match) return <Image src={activeIconSrc} w={'20%'} />;
      else return <Image src={dimIconSrc} w={'20%'} />;
    }
  }

  return (
    <VStack spacing={1} w={'95%'} onClick={() => navigate(to, { replace: false })}>
      <Box h={'15%'} />
      {generateIcon(match, iconAs, activeIconSrc, dimIconSrc)}
      {/* {match ? <Image src={activeIconSrc} w={'20%'} /> : <Image src={dimIconSrc} w={'20%'} />} */}
      {match ? <ActiveIconNameP>{name}</ActiveIconNameP> : <DimIconNameP>{name}</DimIconNameP>}
    </VStack>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string,
  activeIconSrc: PropTypes.string,
  dimIconSrc: PropTypes.string,
};

export default CustomLink;