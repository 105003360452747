import React from 'react';
import PropTypes from 'prop-types';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Box, Image, VStack } from '@chakra-ui/react';
import ImageVCrop from 'components/image/ImageVCrop';
import AccntInfoSimple from './CustomComponents/AccntInfoSimple';
import TotalPointsNum from './CustomComponents/TotalPointsNum';
import MyPromotionsCustom from './CustomComponents/MyPromotionsCustom';
import SemiCircularProgress from 'components/progress/SemiCircularProgress';

const AccountBonvoy = props => {
  const accntInfoSimple0 = (
    <ImageVCrop
      id={'accntInfoSimple0'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='0px'
      h='80px'
    />
  );

  const accntPointStatus1 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='80px'
      h='250px'
    />
  );

  const accntHorizMemberBenefits2 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='330px'
      h='100px'
    />
  );

  const lifetimeStatus3 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='430px'
      h='100px'
    />
  );

  const totalPoints4 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='530px'
      h='100px'
    />
  );

  const totalPoints4_5 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='530px'
      h='35px'
    />
  );

  const myPromotions5 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='630px'
      h='80px'
    />
  );

  const newPromotions6 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='710px'
      h='340px'
    />
  );

  const myFavorites7 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='1050px'
      h='190px'
    />
  );

  const additionalInfo8 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='1240px'
      h='210px'
    />
  );

  const freeNightAwardCard9 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='1450px'
      h='340px'
    />
  );

  const maintainYourPoints10 = (
    <ImageVCrop
      id={'accntPointStatus1'}
      src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
      yStart='1790px'
      h='140px'
    />
  );

  return (
    <>
      <NavbarTop
        back={{ isBack: false, to: -1 }}
      />
      <VStack spacing={0}>
        <AccntInfoSimple name='Nick' accntNum={120092738} membershipLvl={'Platinum Elite'} />
        {/* <SemiCircularProgress /> */}
        {accntPointStatus1}
        {accntHorizMemberBenefits2}
        {lifetimeStatus3}
        {totalPoints4_5}
        <TotalPointsNum points={85211} />
        <MyPromotionsCustom />
        {newPromotions6}
        {myFavorites7}
        {additionalInfo8}
        {freeNightAwardCard9}
        {maintainYourPoints10}
        {/* <Box backgroundColor={'red'} h='100px' w={'100%'} />
        <ImageVCrop
          id={2}
          src='/assets/account/bg/bonvoy_tab_account_full_20221110.jpg'
          yStart='0px'
          h='100%'
        /> */}
      </VStack>
    </>
  );
};

AccountBonvoy.propTypes = {
  
};

export default AccountBonvoy;