import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

const useMembershipInfo = props => {
  const { membership } = props;
  const [membershipList, setMembershipList] = useState([]);

  // axios
  const getMembershipInfo = async (payload) => {
    const url =`${process.env.REACT_APP_BASE_URL}/open-api/get/membership/info`;
    const reqBody = {
      membership: payload?.membership
    };
    const data = await (await axios.post(url, reqBody)).data;
    /**
     * [{ membership_info_seq, membership_type, discount_rate }]
     */
    return data?.result;
  };

  useEffect(() => {
    async function fetchMembershipList() {
      const data = await getMembershipInfo({ membership: membership });
      setMembershipList([...data]);
    }
    fetchMembershipList();
  }, [membership]);

  return {
    membershipList
  };
};

useMembershipInfo.propTypes = {
  
};

export default useMembershipInfo;