import React from 'react';
import PropTypes from 'prop-types';
import { Center, Circle } from '@chakra-ui/react';
import { NotoSansBoldH2 } from 'components/styled';

const NumberInCircle = props => {
  const { value, circleSize, textSize } = props;
  return (
    <Circle size={circleSize || '40px'} background='rgba(170 166 166)'>
      <NotoSansBoldH2 fontSize={textSize || '1.1rem'} color='black'>{value}</NotoSansBoldH2>
    </Circle>
  );
};

NumberInCircle.propTypes = {
  
};

export default NumberInCircle;