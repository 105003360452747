import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from '@chakra-ui/react';
import { NotoSansBoldH2 } from 'components/styled';
import { bonvoyOrange } from 'util/static/bonvoyStyle';
import { SupportedIcon } from 'components';

const EvHomeCard = props => {
  const { width, text, image, icon } = props;
  const { imageSrc, imageSize } = {...image};
  const { iconName, iconSize } = {...icon};
  return (
    <>
      <Box width={width} borderWidth='0.2rem' borderRadius='lg' borderColor={bonvoyOrange} {...props}>
        {/* Text */}
        <Box h={'50%'} w={'80%'} whiteSpace={'normal'} padding={'4vw'}>
          <NotoSansBoldH2 color={'white'} fontSize={'5vw'}>{text}</NotoSansBoldH2>
        </Box>
        {/* Icon */}
        <Box h={'50%'} w={'100%'}>
          <Flex w={'100%'} justifyContent={'flex-end'} padding={'0.5rem'}>
            {imageSrc && <Image src={imageSrc} w={imageSize} />}
            {iconName && <SupportedIcon name={iconName} color={bonvoyOrange} boxSize={iconSize} />}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

EvHomeCard.propTypes = {
  width: PropTypes.string
};

export default EvHomeCard;