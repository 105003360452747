import React from 'react';
import PropTypes from 'prop-types';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Box, Divider, Image, Progress } from '@chakra-ui/react';
import { NotoSansBoldH2, NotoSansH2, WhiteUl } from 'components/styled';
import { useLocation } from 'react-router-dom';

const descs = [
  `Save up to 3,000 bonus points by charging EV and clearing its charger occupation.`,
  `As a Marriott Bonvoy member, after finishing EV charging, clearing your charger occupation under 30 minutes will save you 3,000 points. (2,000 P under 1 hour, or 1,000 P under 2 hours) This promotion is only effective between 11-01-2022 & 12-31-2022.`,
  `This promotion runs on the selected Marriott charging stations. Click here to check the selected Marriott charging stations.`
]

const EvPromoDetail = props => {
  const { state } = useLocation();
  const { chargings, points } = state;

  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
      />
      {/* commoon page margin */}
      <Box margin='10% 10%'>
        <NotoSansBoldH2 fontSize='1.6rem'>
          {`Charge up your EV, earn more Bonvoy points`}
        </NotoSansBoldH2>
        <NotoSansH2 fontSize='1rem'>
          {`Special offer: Earn bonus points on each eligible EV charge, until Dec. 31st`}
        </NotoSansH2>
      </Box>

      <Image
        src={`/assets/account/stock/bonvoy_stock_sunroof.png`}
        w='100%'
      />

      {/* commoon page margin */}
      <Box margin='10% 10%'>
        <NotoSansBoldH2
          style={{ margin: '3% 0' }}
          color={`rgba(150 150 150)`}
        >
          <span style={{ color: chargings!==undefined ? `orange` : `rgba(97 91 87)` }}>
            {chargings || 0}
          </span>
          {` OF 10TH CHARGING`}
        </NotoSansBoldH2>
        <Progress
          size='xs' colorScheme={`orange`} background={`rgba(150 150 150)`}
          value={chargings !== undefined ? chargings*10 : 0}
        />

        <NotoSansH2 fontSize='0.8rem' style={{ marginTop: '10%' }}>
          {`POINTS EARNED`}
        </NotoSansH2>
        <NotoSansBoldH2 fontSize={`2rem`}>
          {`${points.toLocaleString('en', {useGrouping:true}) || 0}`}
        </NotoSansBoldH2>

        <Box marginLeft={`10%`} marginTop={`10%`}>
          <WhiteUl fontFamily={'Helvetica'} fontSize={'0.8rem'} style={{ listStylePosition: 'outside' }}>
            {descs.map((item) => (
              <li style={{ marginBottom: '3px' }}>{item}</li>
            ))}
          </WhiteUl>
        </Box>

        <Divider margin={`10% 0`} color='rgba(97 91 87)' />

        <NotoSansBoldH2 fontSize={`0.7rem`} color='rgba(97 91 87)'>
          {`* This promotion is only for the members of Marriott Bonvoy. The bonus points are served only through the complete EV charging process in this Bonvoy app.`}
        </NotoSansBoldH2>
      </Box>
    </>
  );
};

EvPromoDetail.propTypes = {
  chargings: PropTypes.number,
  points: PropTypes.number
};

export default EvPromoDetail;