import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCookie } from 'util/cookie';
import { useControlChargingStore, useGetChargingStatus } from 'stores/charger/chargingStore';
import { AlertContext } from 'App';
import { useContext } from 'react';


const useControlCP = props => {
  const { url_cp_id, url_connector_id } = props;
  const member_id = getCookie('userId');

  const { openAlert } = useContext(AlertContext);

  const { controlCharging } = useControlChargingStore(state => ({
    controlCharging: state.controlCharging
  }));
  const {
    cp_id,
    connector_id,
    connector_pk,
    connector_type,
    cost,
    energy,
    soc, // 현재 배터리 충전량
    remain_time,
    fetchChargingStatus
  } = useGetChargingStatus(state => ({
    fetchChargingStatus: state.fetchChargingStatus
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState('REST'); // REST, START, CANCEL

  const sendControlReq = async (cmd) => {
    if (!['START','CANCEL'].includes(cmd)) return;
    const resCtrl = await controlCharging({
      member_id: member_id,
      cp_id: url_cp_id,
      connector_id: url_connector_id,
      command: cmd
    });
    if (resCtrl?.status?.code === '1000') {
      openAlert({ bd: 'Failed to control the CP.' })
      return resCtrl
    }
    setIsLoading((cmd==='START' ? true : false));
    setControl(cmd)

    return resCtrl;
  };

  // polling CP status effect since charging STARTed
  useEffect(() => {
    let polling;
    if (isLoading) {
      polling = setInterval(async () => {
        await fetchChargingStatus({
          member_id: member_id,
          cp_id: url_cp_id,
          connector_id: url_connector_id
        });
      }, 3000);
    }

    return () => {
      clearInterval(polling);
    }
  }, [fetchChargingStatus, isLoading, member_id, url_connector_id, url_cp_id]);

  useEffect(() => {
    if (connector_pk !== undefined) {
      setIsLoading(false);
    }
  }, [connector_pk]);

  return {
    isLoading,
    control,
    sendControlReq,
    cp_id,
    connector_id,
    connector_pk,
    connector_type,
    cost,
    energy,
    soc, // 현재 배터리 충전량
    remain_time,
  }
};

useControlCP.propTypes = {
  
};

export default useControlCP;