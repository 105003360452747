import React, { useState } from 'react';

import NavbarTop from 'containers/navbars/NavbarTop';
import { NotoSansExtraBoldH2, NotoSansH2, NotoSansLightH2 } from 'components/styled';
import { HomePageMarginBox } from 'pages/charger/Home';
import { Box, Center, Divider, Flex, Spinner, VStack } from '@chakra-ui/react';
import TxtDividerTxt from 'components/compound/TxtDividerTxt';
import { RoundButton } from 'components';
import { BigRoundButtonProps, BonvoyOrangeRoundButtonsProps } from 'components/props/preDefinedProps';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCookie } from 'util/cookie';
import useBillPayment from './effects/useBillPayment';
import { useAccountStore, useAddPointStore } from 'stores/account/accountStore';
import { AlertContext } from 'App';
import { useContext } from 'react';
import { bonvoyOrange } from 'util/static/bonvoyStyle';


const PointPayment = props => {
  // context
  const { openAlert } = useContext(AlertContext);
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  // url
  const params = new URLSearchParams(location.search);
  const url_cp_id = params.get("cp_id");
  const url_connector_id = params.get("connector_id");
  const member_id = getCookie('userId')
  const connector_pk = `${url_cp_id}0${url_connector_id}`
  // store
  const { postPointHistory } = useAddPointStore(state => ({
    postPointHistory: state.postPointHistory
  }))
  const { name } = useAccountStore(state => ({
    name: state.name
  }))
  // hook (useBillPayment)
  const {
    // state
    chargedBill,
    discountRate,
    preFinalBenefit,
    finalBill,
    finalBillPoint,
    bonvoyBalance,
    bonvoyMembership,
    refillPoints,
    amount,
    pAfterPay,
    // method
    putAccount
  } = useBillPayment({ member_id, connector_pk, cp_id: url_cp_id, connector_id: url_connector_id })
  // state
  const [isLoading, setIsLoading] = useState(false);


  const handleRefillNPay = async () => {
    setIsLoading(true);
    const resStatusAccnt = await putAccount({
      member_id: getCookie('userId'),
      ev_balance: pAfterPay
    });
    console.log(resStatusAccnt)
    const resStatusPnt = await postPointHistory({
      name: name, // swkim
      charging_point: chargedBill, // chargedBill
      use_point: finalBillPoint, // finalBillPoint
      bonvoy_membership: bonvoyMembership, // bonvoyMembership
      discount_point: -preFinalBenefit, // preFinalBenefit
      amount: amount,
      unit_cost: ''
    });
    console.log('Point history upload status:', resStatusPnt)

    if (resStatusAccnt?.code === '100') {
      openAlert({ bd: 'Successfully refilled & paid' });
      navigate('/driverapp', { replace: true })
    } else if (resStatusAccnt?.code === '1000') {
      openAlert({ bd: 'Failed to refill & pay' })
    }
    setIsLoading(false);
  }

  // late response feedback
  if ([
    chargedBill,
    bonvoyMembership,
    preFinalBenefit,
    discountRate,
    finalBill,
    bonvoyBalance,
    refillPoints,
    finalBillPoint,
    pAfterPay
  ].some(item => [null, undefined, NaN].includes(item))) {
    return (
      <>
        <HomePageMarginBox>
          <Center h={'100%'}>
            <NotoSansH2>Loading data</NotoSansH2>
            <Spinner color='white' boxSize={'1rem'} marginLeft={'1rem'} />
          </Center>
        </HomePageMarginBox>
      </>
    )
  }


  return (
    <>
      <NavbarTop
        back={{ isBack: false, to: -1 }}
        middle={<NotoSansExtraBoldH2 fontSize={'1rem'}>Pay with Points</NotoSansExtraBoldH2>}
      />

      {/* <HomePageMarginBox>
        <Center w={'100%'}>
          <CenturyGothicBoldP fontSize={'4.5rem'} color={'rgb(26, 211, 187)'}>{`$${finalBill}`}</CenturyGothicBoldP>
        </Center>
      </HomePageMarginBox> */}

      <HomePageMarginBox>
        <Flex>
          <NotoSansExtraBoldH2 fontSize={'1.2rem'}>Billing Detail</NotoSansExtraBoldH2>
          <NotoSansLightH2 fontSize={'0.9rem'}>{'(100P = 1$)'}</NotoSansLightH2>
        </Flex>

        <VStack w={'100%'} padding={'5% 1%'} spacing={'0.8rem'}>
          <Box w={'97%'}>
            <TxtDividerTxt textLeft='Charging Cost' hideDivider={true} textRight={`${chargedBill} $`} />
          </Box>
          <Box w={'97%'}>
            <TxtDividerTxt textLeft={`Membership: ${bonvoyMembership}`} hideDivider={true} textRight={`${preFinalBenefit} $`} />
            <TxtDividerTxt textLeft={`(Discount ${discountRate}%)`} hideDivider={true} textRight={``} />
          </Box>

          <Divider />

          <Box w={'97%'}>
            <TxtDividerTxt color={bonvoyOrange} textLeft={`Total Cost`} hideDivider={true} textRight={`${finalBill} $`} />
          </Box>

          <Divider borderBottomWidth={'4px'} />

          <Box w={'97%'}>
            <TxtDividerTxt textLeft={`Your Balance`} hideDivider={true} textRight={`${bonvoyBalance} P`} />
          </Box>

          {parseFloat(refillPoints) > 0 &&
          <Box w={'97%'}>
            <TxtDividerTxt textLeft={`Point Refills`} hideDivider={true} textRight={`${refillPoints} P`} />
          </Box>
          }

          <Box w={'97%'}>
            <TxtDividerTxt color={bonvoyOrange} textLeft={`Total Bill`} hideDivider={true} textRight={`${-finalBillPoint} P`} />
          </Box>

          <Divider />

          <Box w={'97%'}>
            <TxtDividerTxt textLeft={`Balance After Pay`} hideDivider={true} textRight={`${pAfterPay} P`} />
          </Box>
        </VStack>

        <Box h={'5vh'} />

        <Center>
          <RoundButton
            isLoading={isLoading}
            width={'85%'}
            {...BonvoyOrangeRoundButtonsProps}
            {...BigRoundButtonProps}
            onClick={(e) => handleRefillNPay()}
          >
            {`${refillPoints > 0 ? "Refill & " : ""}Pay`}
          </RoundButton>
        </Center>
      </HomePageMarginBox>
    </>
  );
};

PointPayment.propTypes = {
  
};

export default PointPayment;