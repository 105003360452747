import create from 'zustand';
import axios from 'axios';
import produce from 'immer';

export const useConnectorListStore = create((set) => ({
  connectorList: [
    /**
     * { "connector_pk", "max_power"kW, "status", "type" },
     */
  ],
  fetchConnectorList: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/connector/info`;
    const data = await (await axios.post(url, {})).data;
    console.log(data);
    set(state => ({
      connectorList: data.result.map((item) => {
        return [`${item.connector_pk}`, `${item.max_power}kW`, `${item.status.toUpperCase()}`, `${item.type}`]
      })
    }))
  }
}));

export const useConnectorStore = create((set) => ({
  connectorInfo: [/** "connector_pk", "max_power"kW, "status", "type" */],
  connector_pk: '',
  max_power: '',
  status: '',
  type: '',
  fetchConnector: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/connector/info`;
    const reqBody = {
      cp_id: payload?.cp_id,
      connector_id: payload?.connector_id
    }
    const data = await (await axios.post(url, reqBody)).data;
    set(state => ({
      connectorInfo: [
        data.result[0]?.connector_pk, // connectorPk
        data.result[0]?.max_power, // evcType
        data.result[0]?.status, // status
        data.result[0]?.type // connectorType
      ]
    }))
  }
}))