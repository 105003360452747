import { setCookie, removeCookie } from "../CookieUtil";

import { authCookieNames } from "./authNames";

export function setLoginCookies({ userId, token, firstName, lastName }) {
  const exp = {
    key: 'year',
    time: 1000
  };
  setCookie('userId', userId, exp);
  if (token) setCookie('token', token, exp);
  if (firstName) setCookie('firstName', firstName, exp);
  if (lastName) setCookie('lastName', lastName, exp);
};

// 인증에 관련된 쿠키 삭제
export function removeLoginCookies() {
  authCookieNames.forEach((tokenName) => removeCookie(tokenName));
};