import styled from 'styled-components';

// Fonts
export const NotoSansExtraBoldH2 = styled.h2`
  font-family: 'NotoSans';
  font-weight: 800;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;
export const NotoSansBoldH2 = styled.h2`
  font-family: 'NotoSans';
  font-weight: bold;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;
export const NotoSansH2 = styled.h2`
  font-family: 'NotoSans';
  font-weight: 400;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;
export const NotoSansLightH2 = styled.h2`
  font-family: 'NotoSans';
  font-weight: 300;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;

export const CenturyGothicNormalP = styled.p`
  font-family: 'CenturyGothic';
  font-weight: normal;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;
export const CenturyGothicBoldP = styled.p`
  font-family: 'CenturyGothic';
  font-weight: bold;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;

export const TimesNewRomItalic = styled.p`
  font-family: 'TimesNewRoman';
  font-style: italic;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
`;

// Unorderd List
export const WhiteUl = styled.ul`
  font-family: '${props => props.fontFamily}';
  font-weight: 400;
  font-size: ${props => props.fontSize};
  color: ${props => props.color || 'white'};
  list-style-position: inside;
`;