import React from 'react';
import PropTypes from 'prop-types';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Image } from '@chakra-ui/react';

const HomeBonvoy = props => {
  return (
    <>
      <NavbarTop
        back={{ isBack: false, to: -1 }}
      />
      <Image src='/assets/home/bg/bonvoy_tab_home_full.jpg' />
    </>
  );
};

HomeBonvoy.propTypes = {
  
};

export default HomeBonvoy;