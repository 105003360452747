import axios from 'axios';
import { setLoginCookies } from 'util/cookie/auth';
import create from 'zustand';

// User class
export class User {
  constructor({ userId, firstName, lastName }) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
  }
  getFullName = () => {
    return `${this.firstName} ${this.lastName}`;
  };
}

const useStore = create((set) => ({
  user: null, // null || User
  setUser: (newUser) => set((state) => ({
    user: newUser
  })),
  deleteCurrUser: () => set(() => ({
    user: null
  })),
  fetchAccount: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/account/info`;
    const reqBody = { member_id: payload?.member_id }
    const data = await (await axios.post(url,reqBody)).data;
    console.log(data)
    
    // setLoginCookies({
    //   userId: data.result?.member_id,
    //   firstName: data.result?.name.split(' ')[0],
    //   lastName: data.result?.name.split(' ')[1],
    // })

    set(state => ({
      user: new User({
        userId: data.result?.member_id,
        firstName: data.result?.name.split(' ')[0],
        lastName: data.result?.name.split(' ')[1],
      })
    }))

    return data;
  }
}));

export const useAuthStore = useStore;