export const hotelCode2Logo = {
  "WSTN": {
    name: "Westin",
    src: ""
  },
  "ATGC": {
    name: "Autograph Collection",
    src: ""
  },
  "ALFT": {
    name: "Aloft",
    src: ""
  },
  "CTYD": {
    name: "Courtyard",
    src: ""
  },
  "FPNT": {
    name: "Four Points",
    src: ""
  },
  "JWMT": {
    name: "JW Marriott",
    src: ""
  },
  "MTEX": {
    name: "Marriott Executive Apartments",
    src: ""
  },
  "ACHT": {
    name: "AC Hotel",
    src: ""
  },
  "SHTN": {
    name: "Sheraton",
    src: ""
  }
}

export const hotelsList = [
  /** [hotelId, hotelName] */
  ["WSTN001", "The Westin Jersey City Newport"],
  ["ATGC053", "The Lexington Hotel, Autograph Collection"],
  ["ALFT015", "Aloft New York LaGuardia Airport"],
  ["CTYD027", "Courtyard New York Manhattan/Chelsea"],
  ["CTYD038", "Courtyard Edgewater NYC Area"],
  ["ACHT009", "AC Hotel New York Times Square"],
  ["SHTN037", "Sheraton Lincoln Harbor Hotel"]
]