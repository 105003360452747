import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import { RoundButton, PWInput } from 'components';
import RoundInput from 'components/input/RoundInput';

import {
  Box,
  Divider,
  VStack
} from '@chakra-ui/react'

import { User, useAuthStore } from 'stores/auth/authStore';
import { setLoginCookies } from 'util/cookie/auth';

import { BigRoundButtonProps, BonvoyOrangeRoundButtonsProps } from 'components/props/preDefinedProps';

const fakeInfo = {
  userId: 'swkim',
  userPw: 'fakePw'
};

const LoginLabel = styled.p`
  font-family: 'CenturyGothic';
  font-weight: normal;
  font-size: 60%;
  color: white;
`;

const LoginFormBox = props => {
  const { width } = props;
  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');
  const { setUser } = useAuthStore((state) => ({ setUser: state.setUser, fetchAccount: state.fetchAccount }));

  let navigate = useNavigate();

  const handleInputChange = (e) => {
    let name = e.target.name;
    if (name === 'userId') setUserId(e.target.value);
    if (name === 'userPw') setUserPw(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userId === fakeInfo.userId && userPw === fakeInfo.userPw) {
      console.log('submit!')
      // set auth cookie
      setLoginCookies({ userId: userId, firstName: 'Sangwoo', lastName: 'Kim' })
      // set auth store
      const fakeUser = new User({ userId: userId, firstName: 'Sangwoo', lastName: 'Kim' });
      setUser(fakeUser);
      // navigate to main (authroization required)
      navigate('/driverapp', { replace: true })
    }
  }

  return (
    <Box width={width}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <VStack spacing={2}>
          <div>
            <div style={{'margin': '0.2rem 1.3rem'}}>
              <LoginLabel>ID</LoginLabel>
            </div>
            <RoundInput
              type='text' name='userId' placeholder='ID' value={userId} onChange={handleInputChange}
            />
          </div>
          <div>
            <div style={{'margin': '0.2rem 1.3rem'}}>
              <LoginLabel>Password</LoginLabel>
            </div>
            <PWInput
              name='userPw' placeholder='PASSWORD' value={userPw} onChange={handleInputChange}
            />
          </div>
          <Box height={'0.1%'} />
          <Divider />
          <Box height={'0.1%'} />
          <RoundButton
            type='submit'
            {...BonvoyOrangeRoundButtonsProps}
            {...BigRoundButtonProps}
          >
            LOGIN
          </RoundButton>
        </VStack>
      </form>
    </Box>
  );
};

LoginFormBox.propTypes = {
  width: PropTypes.string
};

export default LoginFormBox;