import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Divider, VStack } from '@chakra-ui/react';
import RoundInput from 'components/input/RoundInput';
import { RoundButton } from 'components';
import { BigRoundButtonProps, BonvoyOrangeRoundButtonsProps } from 'components/props/preDefinedProps';
import { useNavigate } from 'react-router-dom';

import { AlertContext } from 'App';
import { useContext } from 'react';

const demoTicket = '10102';
const testTicket = '10302';
const demoConnectorPk = 'MRT0000010102'
const testConnectorPk = 'MRT0000010302'

const TicketNumLabel = styled.p`
  font-family: 'NotoSans';
  font-weight: normal;
  font-size: 100%;
  color: white;
`;

const ValetFormBox = props => {
  const { width } = props;
  const navigate = useNavigate();

  const { openAlert } = useContext(AlertContext);

  const [ticketNum, setTicketNum] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    let name = e.target.name;
    if (name === 'ticket') setTicketNum(e.target.value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if ([demoTicket, testTicket].includes(ticketNum)) {
      console.log('ticket submit!')
      // navigate to charger detail
      setTimeout(() => {
        navigate(`/driverapp/charger/charger-detail?connector_pk=MRT00000${ticketNum}&isValet=true`, { replace: true })
      }, 3000)
    } else {
      setTimeout(() => {
        openAlert({ bd: `Can't find the valet ticket number: ${ticketNum}` })
        setIsLoading(false);
      }, 3000)
    }

  }


  return (
    <Box width={width}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <VStack spacing={2}>
          <div>
            <div style={{'margin': '0.2rem 1.3rem'}}>
              <TicketNumLabel>Ticket Number</TicketNumLabel>
            </div>
            <RoundInput
              type='number' name='ticket' placeholder='ticket #' value={ticketNum} onChange={handleInputChange}
            />
          </div>
          <Box height={'0.1%'} />
          <Divider />
          <Box height={'0.1%'} />
          <RoundButton
            type='submit'
            isLoading={isLoading}
            {...BonvoyOrangeRoundButtonsProps}
            {...BigRoundButtonProps}
          >
            Find Valet
          </RoundButton>
        </VStack>
      </form>
    </Box>
  );
};

ValetFormBox.propTypes = {
  width: PropTypes.string
};

export default ValetFormBox;