import React from 'react';
import PropTypes from 'prop-types';
import { background, Box, Center, Grid, GridItem } from '@chakra-ui/react';
import { SupportedIcon } from 'components';
import { NotoSansH2 } from 'components/styled';

const EmptyPromo = props => {
  const { text } = props;

  return (
    <>
      <Box border='2px dashed rgba(73 73 73)' borderRadius='10px' padding='5% 8%'>
        <Grid w='100%' templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={2}>
            <SupportedIcon name='AiOutlineTrophy' w={6} h='100%' color='white' top="50%" left="50%" transform='translateY(-50%, -50%)' />
          </GridItem>
          <GridItem colSpan={10}>
            <NotoSansH2 style={{ whiteSpace: 'pre-line' }} fontSize='0.8rem'>{text || `We're working on new offers.\n Check back soon.`}</NotoSansH2>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

EmptyPromo.propTypes = {
  text: PropTypes.string
};

export default EmptyPromo;