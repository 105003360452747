import React, { useState } from 'react';
// basic stylesheet
import './App.css';

// fonts
import "./fonts/NotoSans/NotoSans-Light.ttf";
import "./fonts/NotoSans/NotoSans-Regular.ttf";
import "./fonts/NotoSans/NotoSans-Medium.ttf";
import "./fonts/NotoSans/NotoSans-Bold.ttf";
import "./fonts/NotoSans/NotoSans-ExtraBold.ttf";
import "./fonts/CenturyGothic/Century Gothic.otf";
import "./fonts/CenturyGothic/Century Gothic Bold.otf";
import "./fonts/TimesNewRomanPSItalicMT/TimesNewRomanPS ItalicMT.ttf";
import "./fonts/Helvetica/Helvetica.ttf";
import "./fonts/Helvetica/Helvetica-Bold.ttf";

import { useNavigate, Outlet } from 'react-router-dom';

import { removeLoginCookies } from 'util/cookie/auth'

import NavbarBot from 'containers/navbars/NavbarBot';
import MrtAlert from 'components/alert/MrtAlert';
import { useDisclosure } from '@chakra-ui/react';

export const LogoutContext = React.createContext();
export const AlertContext = React.createContext();

function App() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');

  const handleLogOut = () => {
    removeLoginCookies();
    navigate('/login', { replace: true })
  }

  const openAlert = ({ hd, bd}) => {
    setHeader(hd);
    setBody(bd);
    onOpen();
  }
  const closeAlert = () => {
    setHeader('');
    setBody('');
    onClose();
  }

  return (
    <>
      <LogoutContext.Provider value={{
        handleLogOut: handleLogOut
      }} >
        <AlertContext.Provider value={{
          openAlert: openAlert
        }}
        >
          <NavbarBot type='driverApp'>
            <Outlet />
          </NavbarBot>
          <MrtAlert
            cancelRef={cancelRef}
            onClose={closeAlert}
            isOpen={isOpen}
            header={header}
            body={body}
          />
        </AlertContext.Provider>
      </LogoutContext.Provider>
    </>
  );
}

export default App;
