import React from 'react';

import { Center, IconButton, InputGroup, InputRightElement } from '@chakra-ui/react'
import RoundInput from 'components/input/RoundInput';
import SupportedIcon from 'components/icons/SupportedIcon';

function PasswordInput(props) {
  const { name, placeholder, onChange, inputProps, inputRightProps, showBtnProps } = props;

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup>
      <RoundInput
        name={name || 'pwInput'}
        // pr='4.5rem'
        type={show ? 'text' : 'password'}
        placeholder={placeholder || 'Enter password'}
        onChange={onChange}
        {...inputProps}
      />
      <InputRightElement width='25%' height={'100%'} {...inputRightProps}>
        <Center>
          <IconButton
            icon={show ? <SupportedIcon name='BsEyeSlash' color={'gray.200'} boxSize={'70%'}/> : <SupportedIcon name='BsEye' color={'gray.200'} boxSize={'70%'}/>}
            onClick={handleClick}
            background={'transparent'}
            isRound
            {...showBtnProps}
          />
        </Center>
      </InputRightElement>
    </InputGroup>
  )
}

const PWInput = PasswordInput;
export default PWInput;