import React from 'react';
// import PropTypes from 'prop-types';

import { Box, Center, VStack, Image } from '@chakra-ui/react';

import { NotoSansExtraBoldH2, CenturyGothicNormalP } from 'components/styled';
import { LoginFormBox } from 'containers';
import SmartEVBackgroundImage from 'components/SmartEVBackgroundImage';

const LoginPage = props => {
  return (
    <>
      <SmartEVBackgroundImage />
      <Center h='100vh'>
        <VStack>
          <Box>
            <VStack>
              <NotoSansExtraBoldH2 fontSize={'1.7rem'}>Stay with EV Charging</NotoSansExtraBoldH2>
              <CenturyGothicNormalP fontSize={'0.8rem'}>Join for the benefits, Stay for the reward</CenturyGothicNormalP>
              <Box height={'5vh'} />
              <LoginFormBox width={'80%'} />
            </VStack>
          </Box>

          <Box h={'10vh'} />

          <Box w={'100vw'}>
            <Center>
              <Image src='/assets/logo/logoNew.png' width={'30vw'} />
            </Center>
          </Box>
        </VStack>
      </Center>
    </>
  );
};

LoginPage.propTypes = {
};

export default LoginPage;