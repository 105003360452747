import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Flex, Spacer } from '@chakra-ui/react';
import { CenturyGothicNormalP } from 'components/styled';

/**
 * Automatically draws a divider between two texts.
 * @param {*} props textLeft, textRight
 * @returns <Flex/>
 */

const TxtDividerTxt = props => {
  const { textLeft, textLeftFontSize, hideDivider, textRight, textRightFontSize } = props;
  return (
    <Flex>
      <CenturyGothicNormalP fontSize={textLeftFontSize || ''} color={props.color}>{textLeft}</CenturyGothicNormalP>
      <Spacer margin={'auto 0.7rem'}>
        {hideDivider ? null : <Divider />}
      </Spacer>
      <CenturyGothicNormalP fontSize={textRightFontSize || ''} color={props.color}>{textRight}</CenturyGothicNormalP>
    </Flex>
  );
};

TxtDividerTxt.defaultProps = {
  dividerMid: false
}

TxtDividerTxt.propTypes = {
  textLeft: PropTypes.string,
  textLeftFontSize: PropTypes.string,
  hideDivider: PropTypes.bool,
  textRight: PropTypes.string,
  textRightFontSize: PropTypes.string
};

export default TxtDividerTxt;