import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Grid, GridItem  } from '@chakra-ui/react';
import { NotoSansH2 } from 'components/styled';
import BonvoySettingsButton from 'components/button/BonvoySettingsButton';

const AccntInfoSimple = props => {
  const { name, accntNum, membershipLvl, onClickSetting } = props;

  return (
    <>
      <Box
        w='100%' h='80px' backgroundColor='#303030'
        padding='3% 0'
      >
        <Center w='100%' h='100%'>
          <Grid
            w='83%' h='100%'
            templateColumns='repeat(12, 1fr)'
            templateRows='repeat(12, 1fr)'
          >
            {/* Sangwoo * 123456789 */}
            <GridItem
              colSpan={10} rowSpan={5}
            >
              <NotoSansH2 fontSize='0.9rem'>
                {name || 'Sangwoo'}{'  '}
                <span style={{ fontSize: '0.5rem' }}>&#9679;</span>{'  '}
                {accntNum || 123456789}
              </NotoSansH2>
            </GridItem>

            {/* Settings button */}
            <GridItem
              colSpan={2} rowSpan={12}
            >
              <Center h={'100%'}>
                <BonvoySettingsButton />
              </Center>
            </GridItem>

            {/* Member */}
            <GridItem
              colSpan={10} rowSpan={7}
            >
              <NotoSansH2 fontSize='1.5rem'>{membershipLvl || 'Member'}</NotoSansH2>
            </GridItem>
          </Grid>
        </Center>
      </Box>
    </>
  );
};

AccntInfoSimple.propTypes = {
  name: PropTypes.string,
  accntNum: PropTypes.number,
  membershipLvl: PropTypes.string,
  onClickSetting: PropTypes.func
};

export default AccntInfoSimple;