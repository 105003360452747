export const calcRefill = (balanceStr, billStr) => {
  let balance = parseFloat(balanceStr);
  let bill = parseFloat(billStr);
  console.log('balance:', balance, 'type:', typeof balance);
  console.log('bill:', bill, 'type:', typeof bill);
  if (balance >= bill) return 0;

  const subBillBal = bill - balance;
  let res = parseInt(subBillBal / 10); // 값
  let remain = subBillBal % 10; // 나머지

  const ans = res*10 + (remain > 0 ? 10 : 0);
  console.log(ans)
  return ans
}