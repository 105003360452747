export const connPk2Name = {
  "SME2047581501": 'Charlie 01',
  "SME2047581502": 'Charlie 02',
  "SME7458130901": 'Brian 01',
  "SME7458130902": 'Brian 02',
  "MRT0000010101": 'William 01',
  "MRT0000010102": 'William 02',
  "MRT0000010201": 'Tom 01',
  "MRT0000010202": 'Tom 02',
  "MRT0000010301": 'Sharon 01',
  "MRT0000010302": 'Sharon 02',
}

export const renderItemTxt = (item, index) => {
  if (index === 0) return connPk2Name?.[item];
  return item;
}