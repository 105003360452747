export const allEvPlans = ['Member', 'Silver', 'Gold', 'Platinum', 'Titanium', 'Ambassador'];

export const allEvPlansDesc = {
  'Member': {
    name: 'Member',
    descriptions: [
      'Discount 5% on every EV charging bill',
    ],
    discountRate: -0.05
  },
  'Silver': {
    name: 'Silver',
    descriptions: [
      'Discount 7% on every EV charging bill',
    ],
    discountRate: -0.07
  },
  'Gold': {
    name: 'Gold',
    descriptions: [
      'Discount 9% on every EV charging bill'
    ],
    discountRate: -0.09
  },
  'Platinum': {
    name: 'Platinum',
    descriptions: [
      'Discount 11% on every EV charging bill'
    ],
    discountRate: -0.11
  },
  'Titanium': {
    name: 'Titanium',
    descriptions: [
      'Discount 13% on every EV charging bill'
    ],
    discountRate: -0.13
  },
  'Ambassador': {
    name: 'Ambassador',
    descriptions: [
      'Discount 15% on every EV charging bill'
    ],
    discountRate: -0.15
  },
}