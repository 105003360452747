import React from 'react';
import { Box, Center, Container, Flex, Image, VStack } from '@chakra-ui/react';
import { CenturyGothicBoldP, NotoSansH2 } from 'components/styled';

import { allEvPlans } from 'util/static/bonvoyPlans';
import useMembershipInfo from 'pages/charger/effects/useMembershipInfo';


const VisitPage = props => {
  const { membershipList } = useMembershipInfo({ membership: null });

  return (
    <>
      <Container
        background={'rgb(255,255,255)'} h={'100vh'}
        position={'relative'} overflow={'scroll'}
        backgroundImage={'url("/assets/bg/bonvoy_google_play.png")'}
        backgroundSize={'100%'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        padding={0}
      >
        <Center h={'100%'} w={'100%'} background={'rgba(255,255,255, 0.7)'}>
          <VStack>
            <CenturyGothicBoldP color='black'>Install Marriott Bonvoy App for further services.</CenturyGothicBoldP>

            {/* List benefits */}
            <NotoSansH2 color='black'>Get discount at every EV charging:</NotoSansH2>
            <Box>
              <ul>
                {(membershipList.length > 0) && allEvPlans.map((mem, index) => (
                  <li>{`${mem}: -${membershipList[index]?.discount_rate}%`}</li>
                ))}
              </ul>
            </Box>

            <Box h={'2rem'} />

            <CenturyGothicBoldP color='black'>Install Marriott Bonvoy</CenturyGothicBoldP>
            <Flex>
              <Image
                src={'/assets/app/google-play-badge.png'}
                w={'50%'}
                onClick={(e) => {
                  window.location.href = 'https://play.google.com/store/apps/details?id=com.marriott.mrt&hl=en'
                }}
              />
              <Box
                w={'50%'} padding={'3.5%'}
              >
                <Image
                  src={'/assets/app/App_Store_Badge_blk.svg'}
                  w={'100%'}
                  onClick={(e) => {
                    window.location.href = 'https://apps.apple.com/us/app/marriott-bonvoy-book-hotels/id455004730'
                  }}
                />
              </Box>
            </Flex>
          </VStack>
        </Center>
      </Container>

    </>
  );
};

VisitPage.propTypes = {
  
};

export default VisitPage;