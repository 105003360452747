import React from 'react';
import NavbarTop from 'containers/navbars/NavbarTop';
import { NotoSansBoldH2, NotoSansExtraBoldH2, NotoSansH2, NotoSansLightH2 } from 'components/styled';
import { HomePageMarginBox } from 'pages/charger/Home';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { hotelsList } from 'util/static/stationsList';
import { useNavigate } from 'react-router-dom';
import { bonvoyOrange } from 'util/static/bonvoyStyle';

const tableFontSize = '0.6rem';

const FindStation = props => {
  const navigate = useNavigate();

  const columnWidth = (i) => {
    if (i === 0) return '30%';
    return '';
  }

  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
        middle={<NotoSansExtraBoldH2 fontSize={'1rem'}>EV Charger</NotoSansExtraBoldH2>}
      />

      <HomePageMarginBox />

      <Box margin={'0 5%'}>
        <NotoSansExtraBoldH2 fontSize={'1.2rem'}>Find Station</NotoSansExtraBoldH2>
        <Box padding={'2% 0'}>
          <NotoSansLightH2 fontSize={'0.8rem'}>Select a charging station where you are located</NotoSansLightH2>
        </Box>
      </Box>

      <TableContainer margin={'5% 3%'}>
        <Table variant={'unstyled'} __css={{
          tableLayout: 'fixed',
          width: '100%'
        }}>
          <Thead backgroundColor={'rgb(89, 89, 89)'}>
            <Tr>
              {["ID", "Hotel Name"].map((item, i) => (
                <Th w={columnWidth(i)} paddingLeft={(i===0 ? '' : '')}>
                  <NotoSansBoldH2 fontSize={tableFontSize}>{item}</NotoSansBoldH2>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody backgroundColor={'rgba(0,0,0, 0.7)'}>
            {hotelsList?.map(row => (
              <Tr
                _active={{
                  backgroundColor: bonvoyOrange
                }}
                onClick={(e) => navigate(
                  `/driverapp/charger/find-charger-options/charger-list`,
                  { replace: false }
                )}
              >
                {row.map((item, i) => (
                  <Td w={columnWidth(i)} paddingLeft={(i===0 ? '' : '')}>
                    <NotoSansBoldH2 fontSize={tableFontSize}>{item}</NotoSansBoldH2>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box h={'10%'} />
    </>
  );
};

FindStation.propTypes = {
  
};

export default FindStation;