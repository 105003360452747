import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const BlurBox = props => {
  return (
    <Box backgroundColor={'rgba(250,250,250, 0.2)'} {...props} >
    </Box>
  );
};

BlurBox.propTypes = {
  borderRadius: PropTypes.string
};

export default BlurBox;