import React from 'react';
import PropTypes from 'prop-types'

import { Button } from '@chakra-ui/react'

/**
 * Custom styled Button. Same as Button's props.
 * @param {*} props Same as the Chakra UI's Button props
 * @returns <Button /> with custom style
 */
const RoundButton = props => {
  const { disabled } = props;
  return (
    <Button borderRadius='50px' disabled={disabled} {...props} >
      {props.children}
    </Button>
  );
};

RoundButton.propTypes = {
  disabled: PropTypes.bool
}

export default RoundButton;