import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useGetBillInfo } from 'stores/charger/chargingStore';
import { useAccountStore, useMembership } from 'stores/account/accountStore';
import { calcRefill } from 'util/calc/BillCalc';


export const convertUsdPoint = (floatStr, unit) => {
  // converts given value to another unit
  /** 10P = $1 */
  const rate = 100;
  if (unit === '$') {
    const res = Math.ceil(parseFloat(floatStr) * rate);
    return res;
  }
  if (unit === 'P') {
    const res = (parseFloat(floatStr) / rate).toFixed(2)
    return res;
  }
}
const parseFloats = (...args) => {
  return args.map(item => parseFloat(item))
}
const parseFloatFix2 = (...args) => {
  return args.map(item => parseFloat(item).toFixed(2))
};

const useBillPayment = props => {
  const { member_id, connector_pk, cp_id, connector_id } = props;

  const { amount, price, currency, fetchBillLatest } = useGetBillInfo(state => ({
    amount: state.amount,
    price: state.price,
    currency: state.currency,
    fetchBillLatest: state.fetchBillLatest
  }));

  const { evPointBalance, bonvoyMembership, fetchAccount, putAccount } = useAccountStore(state => ({
    evPointBalance: state.evPointBalance,
    bonvoyMembership: state.bonvoyMembership,
    fetchAccount: state.fetchAccount,
    putAccount: state.putAccount
  }));

  const { discount_rate, getMembershipInfo } = useMembership(state => ({
    discount_rate: state.discount_rate,
    getMembershipInfo: state.getMembershipInfo
  }))

  // $: float. Point: int (ceiling if it was float)
  const [chargedBill, setChargedBill] = useState(0.0); // 충전기에서 전달 받은 정보로 계산 된 bill
  const [discountRate, setDiscountRate] = useState(0.0);
  const [preFinalBenefit, setPreFinalBenefit] = useState(-0.0); // finall bill 계산 전에 주어지는 혜택 (할인)
  const [finalBill, setFinalBill] = useState(0.0); // chargedBill + (-)preFinalBenefit
  const [finalBillPoint, setFinalBillPoint] = useState(0);

  const [bonvoyBalance, setBonvoyBalance] = useState(0);

  const [refillPoints, setRefillPoints] = useState(0);
  const [postPayBenefit, setPostPayBenefit] = useState(0); // 결제 후에 주어지는 혜택 (추가 지급 포인트)
  const [pAfterPay, setPAfterPay] = useState(0);

  // clean up
  useEffect(() => {
    return () => {
      setChargedBill(null);
    }
  }, []);

  // Fetch data
  useEffect(() => {
    const fetchAll = async () => {
      const dataBill = await fetchBillLatest({
        cp_id: cp_id,
        connector_id: connector_id
      });
      const dataAccnt = await fetchAccount({
        memberId: member_id
      });

      return { dataBill, dataAccnt };
    };

    // prevent unwanted render
    let polling;
    const isNotFalsy = (val) => !!val !== false;
    if ([connector_id, cp_id, fetchAccount, fetchBillLatest, member_id].every(isNotFalsy)) {
      polling = setInterval(() => {
        fetchAll();
      }, 3000);
    }

    return () => {
      clearInterval(polling);
    }
  }, [connector_id, cp_id, fetchAccount, fetchBillLatest, member_id]);

  // fetch membership information
  useEffect(() => {
    if (bonvoyMembership) {
      getMembershipInfo({
        membership: bonvoyMembership
      });
    }
  }, [bonvoyMembership, getMembershipInfo]);

  // totalBill
  useEffect(() => {
    const [amount_, price_] = parseFloats(amount, price);
    const res = parseFloatFix2(amount_ * price_ / 1300)[0];
    setChargedBill(res);
  }, [amount, price]);

  useEffect(() => {
    if (discount_rate) setDiscountRate(discount_rate);
  }, [discount_rate])

  // preFinalBenefit
  useEffect(() => {
    const [chargedBill_, discountRate_] = parseFloats(chargedBill, discountRate);
    const res = parseFloatFix2(chargedBill_ * discountRate_ * -0.01);
    setPreFinalBenefit(res);
  }, [bonvoyMembership, chargedBill, discountRate]);

  // finalBill
  useEffect(() => {
    const [totalBill_, preFinalBenefit_] = parseFloats(chargedBill, preFinalBenefit);
    const res= parseFloatFix2(totalBill_ + preFinalBenefit_);
    setFinalBill(res);
  }, [chargedBill, preFinalBenefit]);

  useEffect(() => {
    const res = convertUsdPoint(finalBill, '$');
    setFinalBillPoint(res);
  }, [finalBill])

  // bonvoyBalance
  useEffect(() => {
    setBonvoyBalance(evPointBalance);
  }, [evPointBalance]);

  // refillPoints
  useEffect(() => {
    const [bonvoyBalance_, finalBillPoint_] = parseFloats(bonvoyBalance, finalBillPoint);
    const res = Math.ceil(calcRefill(bonvoyBalance_, finalBillPoint_));
    setRefillPoints(res);
  }, [bonvoyBalance, finalBillPoint]);

  // pAfterPay
  useEffect(() => {
    const [refillPoints_, bonvoyBalance_, finalBillPoint_, postPayBenefit_] = parseFloats(refillPoints, bonvoyBalance, finalBillPoint, postPayBenefit);
    const res = parseInt(
      refillPoints_ + bonvoyBalance_ - finalBillPoint_ + postPayBenefit_
    );
    setPAfterPay(res);
  }, [refillPoints, bonvoyBalance, finalBillPoint, postPayBenefit]);

  return {
    // state
    chargedBill,
    discountRate,
    preFinalBenefit,
    finalBill,
    finalBillPoint,
    bonvoyBalance,
    bonvoyMembership,
    postPayBenefit,
    refillPoints,
    amount,
    pAfterPay,
    // method
    putAccount
  };
};

useBillPayment.propTypes = {
  member_id: PropTypes.string,
  connector_pk: PropTypes.string,
  cp_id: PropTypes.string,
  connector_id: PropTypes.string,
};

export default useBillPayment;