import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import SupportedIcon from 'components/icons/SupportedIcon';

const BonvoySettingsButton = props => {
  return (
    <>
      <IconButton
        size='lg'
        background='rgba(32 32 32)'
        icon={
          <SupportedIcon name='ChakraSettingsIcon' color='white' />
        }
        _active={{
          background: 'rgba(32 32 32 / .7)',
          border: 'none'
        }}
        _focus={{
          background: 'rgba(32 32 32 / .7)'
        }}
        _hover={{
          background: 'rgba(32 32 32 / .7)'
        }}
      />
    </>
  );
};

BonvoySettingsButton.propTypes = {
  
};

export default BonvoySettingsButton;