import React, { useEffect, useState } from 'react';

import NavbarTop from 'containers/navbars/NavbarTop';
import { NotoSansExtraBoldH2 } from 'components/styled';
import PreCharging from './detail/PreCharging';
import ChargingOnGoing from './detail/ChargingOnGoing';
import { Center, Spinner } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import useDetailStatEffect from './effects/useDetailStatEffect';


const ChargerDetail = props => {
  const location = useLocation();

  const { isCharging, disabledStart, isValet } = useDetailStatEffect(location);

  // check if this valet scenario
  // NOT CHARGING -> show <PreCharging/> but with "Your vehicle hasn't started charging yet." instead of the charger id table
  // CHARGING -> show <ChargingOnGoing/>

  // original scenario
  // NOT CHARGING -> show <PreCharging/>
  // CHARGING -> show <ChargingOnGoing/>

  const generateContent = (isCharging) => {
    if (isCharging === true) return <ChargingOnGoing isValet={isValet} />;
    else if (isCharging === false) return <PreCharging disabledStartBtn={disabledStart} isValet={isValet} />;
    return (
      <Center h={'100%'}>
        <Spinner color='teal' boxSize={'3rem'} />
      </Center>
    );
  }

  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
        middle={<NotoSansExtraBoldH2 fontSize={'1rem'}>EV Charger</NotoSansExtraBoldH2>}
      />
      {console.log('disabledStart: ',disabledStart)}

      {generateContent(isCharging)}
    </>
  );
};

ChargerDetail.propTypes = {
  
};

export default ChargerDetail;