import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider } from '@chakra-ui/react'

import AuthRoute from 'routes/AuthRoute';

// page elements
import LoginPage from 'pages/login/LoginPage';
import Home from 'pages/charger/Home';
import ScanQR from 'pages/charger/ScanQR';
// import Account from 'pages/account/Account';
import Charger from 'pages/charger/Charger';
// import MyCard from 'pages/account/MyCard';
// import EvSubscriptions from 'pages/account/EvSubscriptions';
import ChargerList from 'pages/charger/ChargerList';
import ChargerDetail from 'pages/charger/ChargerDetail';
import PointPayment from 'pages/charger/PointPayment';
import VisitPage from 'pages/visit/VisitPage';
import FindStation from 'pages/charger/FindStation';
import HomeBonvoy from 'pages/home/HomeBonvoy';
import TripBonvoy from 'pages/trip/TripBonvoy';
import BookBonvoy from 'pages/book/BookBonvoy';
import ValetPage from 'pages/valet/ValetPage';
import AccountBonvoy from 'pages/account/AccountBonvoy';
import MyPromotions from 'pages/account/MyPromotions';
import EvPromoDetail from 'pages/account/EvPromoDetail';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="/driverapp" replace />} /> {/** driverapp as the default page for this domain */}
          <Route path='/login' element={<LoginPage />} />
          <Route path='/driverapp' element={
            <AuthRoute>
              <App />
            </AuthRoute>
          }>
            <Route index element={<Navigate to='home' />} />
            {/* <Route path='home' element={<Home />} /> */}
            <Route path='home' element={<HomeBonvoy />} />
            <Route path='book' element={<BookBonvoy />} />
            <Route path='trip' element={<TripBonvoy />} />
            <Route path='charger' element={<Home />} />
            <Route path='charger/find-charger-options' element={<Charger />} />
            <Route path='charger/find-charger-options/qr-scan' element={<ScanQR />} />
            <Route path='charger/find-charger-options/find-station' element={<FindStation />} />
            <Route path='charger/find-charger-options/charger-list' element={<ChargerList />} />
            <Route path='charger/valet' element={<ValetPage />} />
            <Route path='charger/charger-detail' element={<ChargerDetail />} /> {/** Before and on-going of charging page */}
            <Route path='charger/charger-detail/pay' element={<PointPayment />} />
            {/* <Route path='account' element={<Account />} /> */}
            <Route path='account' element={<AccountBonvoy />} />
            <Route path='account/my-promotions' element={<MyPromotions />} />
            <Route path='account/my-promotions/ev-promo-detail' element={<EvPromoDetail />} />
            {/* <Route path='account/my-card' element={<MyCard />} /> */}
            {/* <Route path='account/ev-subscriptions' element={<EvSubscriptions />} /> */}
          </Route>
          <Route path='/visit' element={<VisitPage />} />
          <Route path='*' element={<h1>No Matching Route</h1>} /> {/** Fallback route */}
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
