import React from 'react';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Box, Center, Flex, VStack } from '@chakra-ui/react';
import { NotoSansBoldH2, NotoSansH2, NotoSansLightH2 } from 'components/styled';
import ValetFormBox from 'containers/forms/valet/ValetFormBox';
import BlurBox from 'containers/blurbox/BlurBox';
import { SupportedIcon } from 'components';

const ValetPage = props => {
  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
      />

      <Center h={'100%'}>
        <Box w={'80%'} wordBreak={'normal'}>
          <VStack>
            <Flex w={'100%'}>
              <NotoSansBoldH2 fontSize={'1.5rem'}>
                Track your valet
              </NotoSansBoldH2>
            </Flex>

            <Box h={'1rem'} />

            <BlurBox padding={'7%'} borderRadius={'15px'}>
              <NotoSansLightH2 fontSize={'1rem'}>
                Thank you for using Marriott's valet service, and participating <br/>
                Environmental Protection <SupportedIcon name='RiLeafFill' color='green' /> with your EV.
              </NotoSansLightH2>
            </BlurBox>

            <Box h={'1rem'} />

            <Box padding={'2% 7%'}>
              <NotoSansH2>
                Provide your valet ticket number to see the charging process.
              </NotoSansH2>
            </Box>

            <Box h={'10%'}/>

            <ValetFormBox width='80%' />

            <Box h={'10rem'} />
          </VStack>
        </Box>
      </Center>
    </>
  );
};

ValetPage.propTypes = {
  
};

export default ValetPage;