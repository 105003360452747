import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';
import { useEffect } from 'react';

const _s = 60
const size = `${_s}vw`
const halfSize = `${_s/2}vw`
const indiW = `${_s}vw`

const barStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: size,
  height: size, /* full circle! */
  borderRadius: '50%',
  boxSizing: 'border-box',
  border: '10px solid rgba(44 45 47)',     /* half gray, */
  borderBottomColor: 'rgba(255 255 255)',  /* half azure */
  borderRightColor: 'rgba(255 255 255)'
};

const SemiCircularProgress = props => {
  const [percent, setPercent] = useState(0);
  const [showTooltip, setShowTooltip] = React.useState(false)

  return (
    <>
      <div
        class="progress"
        style={{
          background: 'rgba(194 194 194 / 0.5)',
          position: 'relative',
          margin: '4px',
          float: 'left',
          textAlign: 'center'
        }}
      >
        <div
          class="barOverflow"
          style={{
            position: 'relative',
            overflow: 'hidden', /* Comment this line to understand the trick */
            width: size,
            height: size, /* Half circle (overflow) */
            marginBottom: '-14px' /* bring the numbers up */
          }}
        >
          <div
            class="bar"
            style={{
              ...barStyle,
              transform: `rotate(${(45+(percent*1.8))}deg)`
            }}
          ></div>
          <div
            class="indicator"
            style={{
              background: 'rgba(194 194 194 / 1)',
              position: 'absolute',
              width: indiW,
              height: '5px',
              top: halfSize,
              left: '0',
              transform: `rotate(${(45+((10/75*100)*1.8))-45}deg)`
            }}
          />
          <div
            class="indicator"
            style={{
              background: 'rgba(194 194 194 / 1)',
              position: 'absolute',
              width: indiW,
              height: '5px',
              top: halfSize,
              left: '0',
              transform: `rotate(${(45+((25/75*100)*1.8))-45}deg)`
            }}
          />
          <div
            class="indicator"
            style={{
              background: 'rgba(194 194 194 / 1)',
              position: 'absolute',
              width: indiW,
              height: '5px',
              top: halfSize,
              left: '0',
              transform: `rotate(${(45+((50/75*100)*1.8))-45}deg)`
            }}
          />
          <div
            class="indicator"
            style={{
              background: 'rgba(194 194 194 / 1)',
              position: 'absolute',
              width: indiW,
              height: '5px',
              top: halfSize,
              left: '0',
              transform: `rotate(${(45+((50/75*100)*1.8))-45}deg)`
            }}
          />
        </div>
        <span style={{ color: 'white' }}>{`${percent}%`}</span>
      </div>

      <br />
      <br />
      <br />

      {/* Slider input test */}
      <Slider
        min={0}
        max={100}
        defaultValue={percent}
        onChange={(val) => setPercent(val)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg='teal.500'
          color='white'
          placement='top'
          isOpen={showTooltip}
          label={`${percent}%`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    </>
  );
};

SemiCircularProgress.propTypes = {
  
};

export default SemiCircularProgress;