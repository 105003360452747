import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Grid, GridItem, Text } from '@chakra-ui/react';
import SupportedIcon from 'components/icons/SupportedIcon';
import { NotoSansH2 } from 'components/styled';
import NumberInCircle from 'components/status/NumberInCircle';

const BonvoyMyPromotionsButton = props => {
  const { onClick } = props;
  return (
    <>
      <Box
        // as='button'
        w='83%' h='50px'
        borderRadius='15px'
        background='rgba(48 48 48)'
        border='1px solid rgba(63 63 63)'
        onClick={onClick}
        _active={{
          background: 'rgba(32 32 32 / .7)',
          border: 'none'
        }}
        _focus={{
          background: 'rgba(32 32 32 / .7)'
        }}
      >
        <Center w='100%' h='100%'>
          <Grid templateColumns='repeat(24, 1fr)' w='90%'>
            <GridItem colSpan={3}>
              <SupportedIcon
                name='AiOutlineTrophy'
                w={6} h={6} color='white'
              />
            </GridItem>
            <GridItem colSpan={19}>
              <NotoSansH2 fontSize='1.1rem'>My Promotions</NotoSansH2>
            </GridItem>
            <GridItem colSpan={2}>
              <Center w='100%' h='100%'>
                <NumberInCircle value={1} circleSize='20px' textSize='11px' />
              </Center>
            </GridItem>
          </Grid>
        </Center>
      </Box>
    </>
  );
};

BonvoyMyPromotionsButton.propTypes = {
  onClick: PropTypes.func
};

export default BonvoyMyPromotionsButton;