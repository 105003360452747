import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@chakra-ui/react';
import _ from 'lodash';

// Crop image by height

const ImageVCrop = props => {
  const { id, src, yStart, h } = props;

  return (
    <img
      id={id}
      alt={`vertical crop ${yStart}`}
      style={{
        width: '100%',
        height: h,
        objectFit: 'cover',
        objectPosition: `0 -${yStart}`,
        marginTop: '0'
      }}
      src={_.clone(src)}
    />
  );
};

ImageVCrop.propTypes = {
  src: PropTypes.string,
  yStart: PropTypes.string,
  h: PropTypes.string
};

ImageVCrop.defaultProps = {
  src: 'https://static.wikia.nocookie.net/pokemon/images/6/6c/Char-pikachu.png/revision/latest?cb=20190430034300',
  yStart: 0,
  h: 0
}

export default ImageVCrop;