import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@chakra-ui/react';

const RoundInput = props => {
  const { name, type, placeholder, value, onChange } = props;
  return (
    <Input
      // dynamic values & functions
      type={type} name={name} placeholder={placeholder} value={value} onChange={onChange}
      // style
      size={'lg'} borderRadius={'50px'} color={'white'} border={'none'} background={'rgba(255, 255, 255, 0.3)'}
      // rest props
      {...props}
    />
  );
};

RoundInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default RoundInput;