import React, { useEffect } from 'react';

import NavbarTop from 'containers/navbars/NavbarTop';
import { Box, Center, VStack } from '@chakra-ui/react';
import { CenturyGothicNormalP, NotoSansExtraBoldH2 } from 'components/styled';
import { RoundButton } from 'components';
import { BigRoundButtonProps, BonvoyOrangeRoundButtonsProps, GreyRoundButtonProps } from 'components/props/preDefinedProps';
import { useNavigate } from 'react-router-dom';

import useWatchValet from './effects/useWatchValet';

const Charger = props => {
  const navigate = useNavigate();

  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
      />
      <Center h='100%'>
        <Box w={'90%'}>
          <VStack>
            <NotoSansExtraBoldH2 fontSize={'1.7rem'}>Stay with EV Charging</NotoSansExtraBoldH2>
            <CenturyGothicNormalP fontSize={'0.8rem'}>Join for the benefits, Stay for the reward</CenturyGothicNormalP>
            <Box height={'17vh'} />
            <Box w={'75%'}>
              <RoundButton
                onClick={(e) => navigate('qr-scan', { replace: false })}
                {...BonvoyOrangeRoundButtonsProps}
                {...BigRoundButtonProps}
              >
                QR code
              </RoundButton>
            </Box>
            <Box h={'0.3rem'} />
            <Box w={'75%'}>
              <RoundButton
                onClick={(e) => navigate('find-station', { replace: false })}
                {...GreyRoundButtonProps}
                {...BigRoundButtonProps}
              >
                EV Charger List
              </RoundButton>
            </Box>
          </VStack>
        </Box>
      </Center>
    </>
  );
};

Charger.propTypes = {

};

export default Charger;