import { useState, useEffect } from "react";

export default function useElementScrollListener(elemQuerySelector) {
  const [elem, setElem] = useState();
  const [data, setData] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0
  });

  // set the element by querySelector
  useEffect(() => {
    if (elemQuerySelector !== undefined) {
      const el = document.querySelector(elemQuerySelector);
      setElem(el);
    }
  }, [elemQuerySelector])

  // set up event listeners
  useEffect(() => {
    const handleScroll = () => {
      setData((last) => {
        return {
          x: elem.scrollLeft,
          y: elem.scrollTop,
          lastX: last.x,
          lastY: last.y
        };
      });
    };

    if (elem !== undefined) {
      handleScroll();
      elem.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      if (elem !== undefined) {
        elem.removeEventListener("scroll", handleScroll);
      }
    };
  }, [elem]);

  return data;
}
