import React, { useEffect, useState } from 'react';
import { useGetChargingStatus } from 'stores/charger/chargingStore';

import { getCookie } from 'util/cookie';

/**
 *
 * @param {*} location
 * @returns isCharging, disableStart, isValet
 */
const useDetailStatEffect = (location) => {
  const [isCharging, setIsCharging] = useState(null); // true: user's EV is charging with this connector, false otherwise
  const [disabledStart, setDisabledStart] = useState(true); // true: user doesn't have control to start (e.g. valet & connector not available)

  const { connector_pk, fetchChargingStatus } = useGetChargingStatus(state => ({
    connector_pk: state.connector_pk,
    fetchChargingStatus: state.fetchChargingStatus
  }))

  const params = new URLSearchParams(location.search);
  const url_connector_pk = params.get("connector_pk");
  const url_cp_id = url_connector_pk.slice(0, -2);
  const url_connector_id = url_connector_pk.slice(-1);
  const url_isValet = params.get("isValet"); // user came this page by the valet QR

  useEffect(() => {
    const member_id = getCookie('userId');
    fetchChargingStatus({
      member_id: member_id,
      cp_id: url_cp_id,
      connector_id: url_connector_id
    });
  }, [fetchChargingStatus, url_connector_id, url_cp_id]);

  // watching connector_pk from useGetChargingStatus store
  useEffect(() => {
    // isCharging
    if (typeof connector_pk === 'string' && connector_pk.length > 0) {
      setIsCharging(true);
    }
    else {
      // setDisabledStart(true);
      setIsCharging(false);
    }
    // disableStart
    if (url_isValet !== 'true') setDisabledStart(false); // user has control on connector charging
    else setDisabledStart(true);
  }, [connector_pk, url_isValet])

  return {
    isCharging,
    disabledStart,
    isValet: url_isValet
  }
};


export default useDetailStatEffect;