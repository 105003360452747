import create from 'zustand';
import axios from 'axios';
import { parsePoints } from 'util/calc/Parsing';

export const useAccountStore = create((set) => ({
  member_id: '',
  name: '',
  evPlan: '',
  evPointBalance: null,
  bonvoyMembership: '',
  connectorStatus: null,
  chargingCpId: '',
  chargingCpConnectorId: '',
  fetchAccount: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/account/info`;
    const reqBody = {
      "member_id": payload?.memberId
    }
    const data = await (await axios.post(url, reqBody)).data;
    console.log(data)
    set({
      memberId: data.result?.member_id,
      name: data.result?.name,
      evPlan: data.result?.ev_subscription,
      evPointBalance: data.result?.ev_balance,
      bonvoyMembership: data.result?.bonvoy_membership,
      connectorStatus: data.result?.connector_status,
      chargingCpId: data.result?.charging_cp_id,
      chargingCpConnectorId: data.result?.charging_connector_id
    });
  },
  putAccount: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/modify/account/info`;
    const reqBody = {
      ...payload,
      "member_id": payload?.member_id,
    }
    const data = await (await axios.post(url, reqBody)).data;
    console.log(data)
    return data?.status; // success or fail
  }
}));

export const useAddPointStore = create((set) => ({
  postPointHistory: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/add/point/info`;
    const reqBody = {
      "name": payload?.name,
      "charging_point": payload?.charging_point,
      "use_point": payload?.use_point,
      "bonvoy_membership": payload?.bonvoy_membership,
      "ev_subscription": payload?.ev_subscription,
      "bonus_point": payload?.bonus_point,
      "discount_point": payload?.discount_point,
      "amount": payload?.amount,
      "unit_cost": payload?.unit_cost
    }
    const data = await (await axios.post(url, reqBody)).data;
    return data?.status; // success or fail
  }
}));

export const useMembership = create((set) => ({
  membership_type: null,
  discount_rate: null,
  getMembershipInfo: async (payload) => {
    const url =`${process.env.REACT_APP_BASE_URL}/open-api/get/membership/info`;
    const reqBody = {
      membership: payload?.membership
    };
    const data = await (await axios.post(url, reqBody)).data;
    /**
     * [
     *  { membership_info_seq, membership_type, discount_rate }
     * ]
     */
    set({
      membership_type: data.result[0]?.membership_type,
      discount_rate: data.result[0].discount_rate
    });
    return data?.result;
  }
}))