import React from 'react';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Image } from '@chakra-ui/react';

const TripBonvoy = props => {
  return (
    <>
      <NavbarTop
        back={{ isBack: false, to: -1 }}
      />
      <Image src='/assets/trip/bg/bonvoy_tab_trips_full.jpg' />
    </>
  );
};

TripBonvoy.propTypes = {
  
};

export default TripBonvoy;