import React, { useEffect, useState } from 'react';
import { HomePageMarginBox } from 'pages/charger/Home';
import { Box, Center, Flex, Progress, Spacer, Spinner } from '@chakra-ui/react';
import { CenturyGothicBoldP, CenturyGothicNormalP, NotoSansBoldH2 } from 'components/styled';
import { RoundButton, SupportedIcon } from 'components';
import { BigRoundButtonProps, GreyRoundButtonProps } from 'components/props/preDefinedProps';
import { useLocation, useNavigate } from 'react-router-dom';
import { useControlChargingStore, useGetChargingStatus } from 'stores/charger/chargingStore';
import { getCookie } from 'util/cookie';
import { bonvoyOrange } from 'util/static/bonvoyStyle';
import { AlertContext } from 'App';
import { useContext } from 'react';

const ChargingOnGoing = props => {
  const { isValet } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { openAlert } = useContext(AlertContext);

  const { soc, remain_time, fetchChargingStatus } = useGetChargingStatus(state => ({
    soc: state.soc,
    remain_time: state.remain_time,
    fetchChargingStatus: state.fetchChargingStatus
  }));
  const { controlCharging } = useControlChargingStore(state => ({
    controlCharging: state.controlCharging
  }));

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const member_id = getCookie('userId');
    const params = new URLSearchParams(location.search);
    const url_connector_pk = params.get("connector_pk");
    const url_cp_id = url_connector_pk.slice(0, -2);
    const url_connector_id = parseInt(url_connector_pk.slice(-1));
    // data polling
    const polling = setInterval(async () => {
      const dataGet = await fetchChargingStatus({
        member_id: member_id,
        cp_id: url_cp_id,
        connector_id: url_connector_id
      });
    }, 5000);

    return () => clearInterval(polling);
  }, [fetchChargingStatus, location.search]);

  const handleStop = async () => {
    const member_id = getCookie('userId');
    const params = new URLSearchParams(location.search);
    const url_connector_pk = params.get("connector_pk");
    const url_cp_id = url_connector_pk.slice(0, -2);
    const url_connector_id = parseInt(url_connector_pk.slice(-1));
    await setIsLoading(true);
    const dataCtrl = await controlCharging({
      member_id: member_id,
      cp_id: url_cp_id,
      connector_id: url_connector_id,
      command: 'CANCEL'
    });
    if (dataCtrl?.status?.code === '1000') {
      openAlert({ bd: 'Already stopped.' });
      setIsLoading(false);
      return;
    }

    let dataGet;
    setTimeout(async () => {
      dataGet = await fetchChargingStatus({
        member_id: member_id,
        cp_id: url_cp_id,
        connector_id: url_connector_id
      });
      if (dataGet?.status?.code === '1000') {
        setIsLoading(false);
        navigate(`pay?cp_id=${url_cp_id}&connector_id=${url_connector_id}`, { replace: false });
      }
    }, 3000)

  };

  const sec2hhmmss = (sec=0) => {
    let result = new Date(sec * 1000).toISOString().slice(11, 19);
    return result;
  }

  const estiPercent = (soc) => {
    if (!!soc) {
      if (soc < 80) return 'until 80%';
      else if (80 <= soc && soc <= 100) return 'until 100%'
    }
    return '';
  }

  return (
    <>
      <HomePageMarginBox>
        <Center h={'27vh'}>
          <CenturyGothicBoldP fontSize={(soc!==null ? '8rem' : '3rem')} color={bonvoyOrange}>
            {soc!==null ?
              `${soc}%`
              :
              `Initiating`
            }
          </CenturyGothicBoldP>
          {/* <Image marginLeft={'0.5rem'} boxSize={'6rem'} src='/assets/percent/percent@3x.png' /> */}
        </Center>
        <Progress value={parseInt(soc)} size='xs' colorScheme={'orange'} isIndeterminate={soc===null} />

        <Box h={'10vh'} />

        <Box margin={'10% 0'}>
          <Center>
            <CenturyGothicNormalP>{`Estimate Time Remaining ${estiPercent(soc)}`}</CenturyGothicNormalP>
          </Center>
          <Center>
            {!!remain_time ?
              <CenturyGothicBoldP fontSize={'4rem'}>{sec2hhmmss(remain_time)}</CenturyGothicBoldP>
              :
              <Flex w={'70%'}>
                <Box>
                  <CenturyGothicBoldP fontSize={'2rem'}>{'Calculating '}</CenturyGothicBoldP>
                </Box>
                <Spacer />
                <Center h={'3rem'}>
                  <Spinner boxSize={'2rem'} color='white' thickness='4px' />
                </Center>
              </Flex>
            }
          </Center>
        </Box>

        <Center>
          <RoundButton
            isLoading={isLoading}
            disabled={isValet==='true'}
            onClick={async (e) => {
              await handleStop();
            }}
            {...GreyRoundButtonProps}
            {...BigRoundButtonProps}
            width={'85%'}
          >
            <SupportedIcon name='BsFillCircleFill' color={'red'} boxSize={'0.7rem'} margin={'5% 5%'} />
            <NotoSansBoldH2 color={'red'} fontSize={'1rem'}>STOP CHARGE</NotoSansBoldH2>
          </RoundButton>
        </Center>
      </HomePageMarginBox>
    </>
  );
};

ChargingOnGoing.propTypes = {
  
};

export default ChargingOnGoing;