import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Progress } from '@chakra-ui/react';
import { NotoSansBoldH2, NotoSansExtraBoldH2, NotoSansH2 } from 'components/styled';
import { SupportedIcon } from 'components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const EvPromo = props => {
  const [chargings, setChargings] = useState(5);
  const [points, setPoints] = useState(15000);
  const navigate = useNavigate();

  return (
    <Box
      border='1px solid rgba(63 63 63)' borderRadius='10px'
      backgroundImage={`url("/assets/account/stock/bonvoy_stock_sunroof.png")`}
      backgroundSize={`cover`}
    >
      <Box
        background='rgba(59,58,56, 0.3)'
        padding='5% 8%'
      >
        <NotoSansExtraBoldH2 style={{ whiteSpace: 'pre-line' }}>
          {`Charge up your EV, earn more Bonvoy points`}
        </NotoSansExtraBoldH2>
        <NotoSansH2 fontSize='0.8rem' style={{ margin: '2% 0' }}>
          {`Effective until 12-31-2022`}
        </NotoSansH2>

        <Box padding={`3%`} background='rgba(240 240 240 / 0.5)' borderRadius={`10px`}>
          <NotoSansBoldH2
            style={{ margin: '3% 0' }}
            color={`rgba(97 91 87)`}
          >
            <span style={{ color: chargings!==undefined ? `orange` : `rgba(97 91 87)` }}>
              {chargings || 0}
            </span>
            {` OF 10TH CHARGING`}
          </NotoSansBoldH2>
          <Progress
            size='xs' colorScheme={`orange`} background={`rgba(97 91 87)`}
            value={chargings !== undefined ? chargings*10 : 0}
          />
        </Box>

        <NotoSansH2 fontSize='0.8rem' style={{ marginTop: '10%' }}>
          {`POINTS EARNED`}
        </NotoSansH2>
        <NotoSansBoldH2 fontSize={`2rem`}>
          {`${points.toLocaleString('en', {useGrouping:true}) || 0}`}
        </NotoSansBoldH2>

        <Box
          as='button' w={`100%`} background={`transparent`} color={`white`}
          onClick={(e) => navigate('ev-promo-detail', { state: { chargings: chargings, points: points } })}
        >
          <Flex direction={`row-reverse`}>
            <SupportedIcon name='ChakraArrowForwardIcon' w={`1.5rem`} h={`1.5rem`} />
            <NotoSansBoldH2 fontSize={`1rem`}>
              {`Book Now`}
            </NotoSansBoldH2>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

EvPromo.propTypes = {
  
};

export default EvPromo;