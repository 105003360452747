import React from 'react';
import PropTypes from 'prop-types';
import NavbarTop from 'containers/navbars/NavbarTop';
import { Box } from '@chakra-ui/react';
import { NotoSansBoldH2, NotoSansH2 } from 'components/styled';
import EmptyPromo from './CustomComponents/EmptyPromo';
import EvPromo from './CustomComponents/EvPromo';

const MyPromotions = props => {
  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
      />
      {/* commoon page margin */}
      <Box margin='10% 10%'>
        <NotoSansH2 fontSize='1.8rem'>Promotions</NotoSansH2>
        {/* New Promotions */}
        <Box margin='4% 0'>
          <EmptyPromo text={`We're working on new offers.\n Check back soon.`} />
        </Box>

        {/* Registered Promotions */}
        <Box margin='4% 0'>
          <NotoSansBoldH2 style={{ margin: '4% 0' }} fontSize='0.8rem' color='grey'>REGISTERD PROMOTIONS</NotoSansBoldH2>
          {/* <EmptyPromo text={`Make the most of your membership. Register now for any new promotions.`} /> */}
          <EvPromo />
        </Box>

        {/* Past Promotions */}
        <Box margin='4% 0'>
          <NotoSansBoldH2 style={{ margin: '4% 0' }} fontSize='0.8rem' color='grey'>PAST PROMOTIONS</NotoSansBoldH2>
          <EmptyPromo text={`You haven't completed any promotions. Yet.`} />
        </Box>
      </Box>
    </>
  );
};

MyPromotions.propTypes = {
  
};

export default MyPromotions;