import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Box } from '@chakra-ui/react'

import { MenuData, MenuItem } from './MenuData';
import CustomLink from 'containers/navbars/CustomLink';

const _menus_driverApp = new MenuData({
  size: 4,
  items: [
    new MenuItem({
      name: 'HOME',
      route: 'home',
      iconAs: 'RiHome2Line',
      // activeIconSrc: '/assets/home/active/icHomeSelected.png',
      // dimIconSrc: '/assets/home/dim/icHomeDim.png'
    }),
    new MenuItem({
      name: 'BOOK',
      route: 'book',
      iconAs: 'AiOutlineCalendar',
    }),
    new MenuItem({
      name: 'TRIP',
      route: 'trip',
      iconAs: 'RiSuitcaseLine',
      // activeIconSrc: '/assets/trip/dim/icTripDim.png',
      // dimIconSrc: '/assets/trip/dim/icTripDim.png'
    }),
    new MenuItem({
      name: 'ACCOUNT',
      route: 'account',
      iconAs: 'BsPerson',
      // activeIconSrc: '/assets/account/active/icAccountSelected.png',
      // dimIconSrc: '/assets/account/dim/icAccountDim.png'
    })
  ]
})

/**
 * Bottom Navbar with N menus.
 * Menu items are static, since this is a demo app.
 */

const NavbarBot = props => {

  if (props.type === 'valetWebSite') return;

  // type: driverApp
  return (
    <>
      <Box
        id='container_above_navbarbot' position={'relative'} overflow={'scroll'}
        // height={'88vh'}
        height={`calc(var(--vh, 1vh) * 88)`}
        backgroundImage={'url("/assets/bg/bg@3x.png")'}
        backgroundSize={'cover'}
        backgroundColor={'rgba(0,0,0, 1)'}
      >
        {props.children}
      </Box>
      <Grid
        position={'relative'} pos="relative" backgroundColor={'#282c34'}
        // height={'12vh'}
        height={`calc(var(--vh, 1vh) * 12)`}
        templateColumns={`repeat(${_menus_driverApp.size}, 1fr)`} gap={3}
        templateRows={'100%'}
      >
        {_menus_driverApp.items.map((item, index) => {
          return (
            <CustomLink
              key={item.name}
              to={item.route}
              name={item.name}
              iconAs={item?.iconAs}
              activeIconSrc={item?.activeIconSrc}
              dimIconSrc={item?.dimIconSrc}
            />
          )
        })}
      </Grid>
    </>
  );
};

NavbarBot.propTypes = {
  type: PropTypes.oneOf(['driverApp', 'valetWebSite'])
};

export default NavbarBot;