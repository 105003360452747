import React, { useEffect, useState } from 'react';
import { useConnectorStore } from 'stores/charger/connectorStore';
import { chargerWatching_dev } from 'util/static/chargerWatching';

/**
 * Watch specific connector_pk statically (for now).
 * If the connector_pk's status is changed to "Charging", return isWatchingConnected as "true".
 * @returns 
 */
const useWatchValet = props => {
  const { watch_cp_id, watch_connector_id } = props;
  // const navigate = useNavigate();
  const { connectorInfo, fetchConnector } = useConnectorStore(state => ({
    connectorInfo: state.connectorInfo,
    fetchConnector: state.fetchConnector
  }));
  const [isWatchigConnected, setIsWatchingConnected] = useState(false);
  const [connector_pk, setConnector_pk] = useState('');

  useEffect(() => {
    let polling;
    if (fetchConnector !== undefined) {
      polling = setInterval(() => {
        fetchConnector({
          cp_id: watch_cp_id,
          connector_id: watch_connector_id
        });
      }, 3000);
    }

    return () => {
      clearInterval(polling);
    }
  }, [fetchConnector, watch_connector_id, watch_cp_id]);

  useEffect(() => {
    if (connectorInfo.length === 4) {
      const isValetWatching = connectorInfo[0] === chargerWatching_dev.connector_pk;
      const isCharging = connectorInfo[2] === "Charging";
      if (isValetWatching && isCharging) {
        setIsWatchingConnected(true);
        setConnector_pk(connectorInfo[0]);
      }
    }
  }, [connectorInfo]);

  return {
    isWatchigConnected,
    connector_pk
  };
};

useWatchValet.propTypes = {
  
};

export default useWatchValet;