import moment from 'moment';

export function setCookie(
  name,
  value,
  exp = {
    time: 0,
    key: ''
  }
) {
  const cookieDate = new Date(moment().add(exp.time, exp.key).toDate()).toUTCString(); // toUTCString for cookie expiration
  document.cookie = `${name}=${value};expires=${cookieDate};path=/`;
};

export function getCookie(name) {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
};

export function removeCookie(name) {
  document.cookie = `${name}=; max-age: -1;`;
};