/**
 * For RoundButtons.
 */
export const BigRoundButtonProps = {
  size: 'lg',
  width: '100%'
}

/**
 * For RoundButtons. Teal RoundButtons.
 */
export const TealRoundButtonProps = {
  colorScheme: 'teal',
  backgroundColor: 'teal.300'
}

export const GreyRoundButtonProps = {
  backgroundColor: 'rgb(67,67,67)',
  color: 'white',
  _active: { backgroundColor: 'rgb(35,35,35)' },
  _hover: { backgroundColor: 'rgb(35,35,35)' }
}

export const BonvoyOrangeRoundButtonsProps = {
  backgroundColor: 'rgb(246,134,90)',
  color: 'white',
  _active: { backgroundColor: 'rgb(133,68,42)' },
  _hover: { backgroundColor: 'rgb(133,68,42)' }
}