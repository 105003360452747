export class MenuData {
  constructor({
    size, items
  }) {
    this.size = size;
    this.items = items || [];
  }

  /**
   * @param {MenuItem} item
   */
  addItem = (item) => {
    this.items.append(item);
  }
};

export class MenuItem {
  /**
   * Menu item data object
   * @param {String} name name of the menu item
   * @param {String} absRoute absolute route where user will be redirected when clicking this menu item
   * @param {String} activeIconSrc src of active-icon to use
   * @param {String} dimIconSrc src of dim-icon to use
   */
  constructor(
    {name, route, iconAs, activeIconSrc, dimIconSrc}
  ) {
    this.name = name;
    this.route = route;
    this.iconAs = iconAs;
    this.activeIconSrc = activeIconSrc;
    this.dimIconSrc = dimIconSrc;
  }
};