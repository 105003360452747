import React from 'react';
import { useConnectorListStore } from 'stores/charger/connectorStore';

import NavbarTop from 'containers/navbars/NavbarTop';
import { NotoSansBoldH2, NotoSansExtraBoldH2, NotoSansH2, NotoSansLightH2 } from 'components/styled';
import { HomePageMarginBox } from 'pages/charger/Home';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { bonvoyOrange } from 'util/static/bonvoyStyle';
import { renderItemTxt } from 'util/static/connectorPk2Name';

const chargerTable = {
  th: ['ID', 'EVC TYPE', 'STATUS', 'CONNECTOR'],
  body: [
    ['1', '100kW', 'AVAILABLE', 'AC 5'],
    ['2', '100kW', 'AVAILABLE', 'AC 5'],
    ['3', '350kW', 'CHARGING', 'DC Combo 7'],
  ]
}

const tableFontSize = '0.8rem';

const ChargerList = props => {
  const navigate = useNavigate();
  const { connectorList, fetchConnectorList } = useConnectorListStore(state => ({
    connectorList: state.connectorList,
    fetchConnectorList: state.fetchConnectorList
  }));

  React.useEffect(() => {
    fetchConnectorList();
  }, [fetchConnectorList])

  React.useEffect(() => {
    console.log(connectorList)
  }, [connectorList])

  const columnWidth = (i) => {
    if ([1,2].includes(i)) return '22%';
    return '';
  }

  return (
    <>
      <NavbarTop
        back={{ isBack: true, to: -1 }}
        middle={<NotoSansExtraBoldH2 fontSize={'1rem'}>EV Charger</NotoSansExtraBoldH2>}
      />

      <HomePageMarginBox />

      <Box margin={'0 5%'}>
        <NotoSansExtraBoldH2 fontSize={'1.2rem'}>Information</NotoSansExtraBoldH2>
        <Box padding={'2% 0'}>
          <NotoSansLightH2 fontSize={'0.8rem'}>Select a connector from the list to charge it</NotoSansLightH2>
        </Box>
      </Box>

      <TableContainer margin={'5%'}>
        <Table variant={'unstyled'} __css={{
          tableLayout: 'fixed',
          width: '100%'
        }}>
          <Thead backgroundColor={'rgb(89, 89, 89)'}>
            <Tr>
              {chargerTable.th.map((item, i) => (
                <Th paddingLeft={(i===0 ? '' : '2')} w={columnWidth(i)}>
                  <NotoSansBoldH2 fontSize={tableFontSize}>{item}</NotoSansBoldH2>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody backgroundColor={'rgba(0,0,0, 0.7)'}>
            {connectorList?.map(row => (
              <Tr
                _active={{
                  backgroundColor: bonvoyOrange
                }}
                onClick={(e) => navigate(
                  `/driverapp/charger/charger-detail?connector_pk=${row[0]}`,
                  { replace: false }
                )}
              >
                {row.map((item, i) => (
                  <Td paddingLeft={(i===0 ? '2' : '2')} w={columnWidth(i)}>
                    <NotoSansBoldH2 fontSize={tableFontSize}>{renderItemTxt(item, i)}</NotoSansBoldH2>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box h={'20%'} />
    </>
  );
};

ChargerList.propTypes = {
  
};

export default ChargerList;