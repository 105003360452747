import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Image, Grid, GridItem } from '@chakra-ui/react';
import { SupportedIcon } from 'components';
import { useNavigate } from 'react-router-dom';
import useElementScrollListener from 'hooks/useElementScrollListener';


const NavbarTop = props => {
  const { back } = props;
  const navigate = useNavigate();

  const [hidden, setHidden] = useState(false); // false: show navbar; true: hide navbar
  const scroll = useElementScrollListener('#container_above_navbarbot');

  // update hidden state of navbar on scroll
  useEffect(() => {
    let isHidden = false;

    if (scroll.y > 50 && scroll.y - scroll.lastY > 0) {
      isHidden = true;
    }

    setHidden(isHidden);
  }, [scroll.y, scroll.lastY]);

  const generateBackButton = () => {
    if (back?.isBack) return (
      <SupportedIcon
        name='AiOutlineArrowLeft' color='white' boxSize='6vw' margin='auto 1rem'
        onClick={(e) => {navigate(back?.to || -1)}}
      />
    );
  };

  return (
    <Box
      className='sticky-navbar' position={'sticky'} height={'10%'} top={'0'} backgroundColor='#282c34'
      transition={'transform 150ms ease-in-out'}
      transform={hidden ? 'translateY(-100%)' : ''}
      zIndex={10}
    >
      <Center position={'absolute'} bottom={'10%'} w='100%'>
        <Grid templateColumns={'repeat(3, 1fr)'} w='100%'>
          <GridItem>
            {generateBackButton()}
          </GridItem>
          <GridItem>
            <Center h={'100%'}>
              <Image src='/assets/logo/smartEvLogo.png' width={'20vw'} marginBottom={'2'} />
            </Center>
          </GridItem>
          <GridItem />
        </Grid>
      </Center>
    </Box>
  );
};

NavbarTop.propTypes = {
  back: PropTypes.exact({
    isBack: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};

export default NavbarTop;