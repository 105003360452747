import React, { useEffect } from 'react';
import { useConnectorStore } from 'stores/charger/connectorStore';
import { HomePageMarginBox } from 'pages/charger/Home';
import { Box, Center, Image, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { CenturyGothicBoldP, NotoSansH2 } from 'components/styled';
import { RoundButton } from 'components';
import { BigRoundButtonProps, BonvoyOrangeRoundButtonsProps } from 'components/props/preDefinedProps';
import { useLocation } from 'react-router-dom';
import useControlCP from '../effects/useControlCP';
import { renderItemTxt } from 'util/static/connectorPk2Name';

const detailTable = {
  th: ['ID', 'EVC TYPE', 'STATUS', 'CONNECTOR'],
  body: [
    ['1', '100kW', 'Ready', 'AC5']
  ]
}
const tableFontSize = {
  th: '0.6rem',
  td: '0.9rem'
};


const PreCharging = props => {
  const { disabledStartBtn } = props;
  const location = useLocation();
  const { connectorInfo, fetchConnector } = useConnectorStore(state => ({
    connectorInfo: state.connectorInfo,
    fetchConnector: state.fetchConnector
  }));

  // Read URL param
  const queryString = location.search;
  let params = new URLSearchParams(queryString);
  let url_connector_pk = params.get("connector_pk");
  const url_cp_id = url_connector_pk.slice(0, -2);
  const url_connector_id = parseInt(url_connector_pk.slice(-1));

  // fetch Connector info at mount.
  useEffect(() => {
    fetchConnector({
      cp_id: url_cp_id,
      connector_id: url_connector_id
    })
  }, [fetchConnector, url_connector_id, url_cp_id]);

  const {
    isLoading,
    sendControlReq,
  } = useControlCP({
    url_cp_id: url_cp_id, url_connector_id: url_connector_id
  });


  const columnWidth = (i) => {
    if (i === 0) return '30%';
    if (i === 2) return '22%';
    return '';
  }

  return (
    <>
      <HomePageMarginBox>
        <Center>
          <Image
            borderRadius={'full'}
            boxSize={'90%'}
            src={'/assets/car/carImg@3x.png'}
            alt={'Plug Charger Img'}
          />
        </Center>
      </HomePageMarginBox>

      <Box margin={'auto 5%'}>
        <TableContainer margin={'auto'}>
          <Table variant={'unstyled'} __css={{
            tableLayout: 'fixed',
            width: '100%'
          }}>
            <Thead>
              <Tr>
                {detailTable.th.map((item, i) => (
                  <Th w={columnWidth(i)} paddingLeft={(i===0 ? '' : '')} borderBottom={'1px solid rgba(255,255,255, 0.2)'}>
                    <NotoSansH2 color={'rgba(255,255,255, 0.8)'} fontSize={tableFontSize.th}>{item}</NotoSansH2>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {detailTable.body.map((row) => (
                <Tr>
                  {connectorInfo.map((item, i) => (
                    <Td w={columnWidth(i)} paddingLeft={(i===0 ? '' : '')}>
                      <CenturyGothicBoldP fontSize={tableFontSize.td}>{(i===1 ? `${item}kW` : renderItemTxt(item, i))}</CenturyGothicBoldP>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Box h={'5%'} />

      {/* disable if its not connected */}
      <Center>
        <Box w={'75%'}>
          {console.log(disabledStartBtn)}
          <RoundButton
            isLoading={isLoading}
            disabled={disabledStartBtn}
            {...BonvoyOrangeRoundButtonsProps}
            {...BigRoundButtonProps}
            onClick={(e) => sendControlReq('START')}
          >
            START
          </RoundButton>
        </Box>
      </Center>
    </>
  );
};

PreCharging.propTypes = {
  
};

export default PreCharging;