import React from 'react';
import PropTypes from 'prop-types';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';

const MrtAlert = props => {
  const { cancelRef, onClose, isOpen, header, body } = props;
  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          backgroundColor={'rgba(89,89,89, 1)'}
        >
          <AlertDialogHeader color={'white'}>{header}</AlertDialogHeader>
          <AlertDialogCloseButton color={'white'} />
          <AlertDialogBody
            color={'white'}
          >
            {body}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef} onClick={onClose}
              colorScheme='orange'
            >
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

MrtAlert.propTypes = {
  
};

export default MrtAlert;