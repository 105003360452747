import React from 'react';
import { useAccountStore, useMembership } from 'stores/account/accountStore';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import NavbarTop from 'containers/navbars/NavbarTop';
import { NotoSansExtraBoldH2, CenturyGothicNormalP, TimesNewRomItalic, WhiteUl } from 'components/styled';
import { getCookie } from 'util/cookie';
import TxtDividerTxt from 'components/compound/TxtDividerTxt';
import BlurBox from 'containers/blurbox/BlurBox';

import { allEvPlans } from 'util/static/bonvoyPlans';
import { useEffect } from 'react';
import EvHomeCard from 'containers/card/EvHomeCard';
import { useNavigate } from 'react-router-dom';

export const HomePageMarginBox = (props) => {
  return(
    <Box margin={'15% 10%'} {...props}>{props.children}</Box>
  );
}


// style constants
const TxtDivTxtFontSize = '4.2vw';


/**
 * @context App.js/LogoutContext
 * @param {*} props
 * @returns Home (sub)page
 */

const Home = props => {
  const navigate = useNavigate();
  const { name, evPointBalance, bonvoyMembership, fetchAccount } = useAccountStore(state => ({
    name: state.name,
    evPlan: state.evPlan,
    evPointBalance: state.evPointBalance,
    bonvoyMembership: state.bonvoyMembership,
    fetchAccount: state.fetchAccount
  }));
  const { discount_rate, getMembershipInfo } = useMembership(state => ({
    discount_rate: state.discount_rate,
    getMembershipInfo: state.getMembershipInfo
  }));

  React.useEffect(() => {
    fetchAccount({
      memberId: 'swkim'
    });
  }, [fetchAccount])

  useEffect(() => {
    if (allEvPlans.includes(bonvoyMembership)) {
      getMembershipInfo({ membership: bonvoyMembership});
    }
  }, [bonvoyMembership, getMembershipInfo]);

  return (
    <>
      <NavbarTop
        back={{ isBack: false, to: -1 }}
      />

      <HomePageMarginBox>
        <NotoSansExtraBoldH2 fontSize={'11vw'}>Hello, {name.split(' ')[0]}</NotoSansExtraBoldH2>
        <CenturyGothicNormalP fontSize={'4.5vw'}>Hyundai Ioniq5</CenturyGothicNormalP>
      </HomePageMarginBox>

      <Box margin={'0% 10%'}>
        <Flex w={'100%'}>
          <EvHomeCard
            width={'38.5vw'}
            text={'Find Charger'}
            image={{
              imageSrc: '/assets/charger/active/icEvSelected.png',
              imageSize: '40%'
            }}
            onClick={(e) => {
              navigate('find-charger-options', { replace: false })
            }}
          />
          <Spacer />
          <EvHomeCard
            width={'38.5vw'}
            text={'Valet'}
            icon={{
              iconName: 'GiCarKey',
              iconSize: '40%'
            }}
            onClick={(e) => {
              navigate('valet', { replace: false })
            }}
          />
        </Flex>
      </Box>

      <Box h={'9%'} />

      <Box position={'absolute'} w={'100%'} backgroundColor={'rgba(250,250,250, 0.2)'} backdropFilter={'blur(10px)'} borderRadius={'0 120px 0 0'} >
        <HomePageMarginBox>
          <NotoSansExtraBoldH2 fontSize={'5vw'}>Your Bonvoy Points</NotoSansExtraBoldH2>
          <Box margin={'3%'}>
            <TxtDividerTxt textLeft='Balance' textLeftFontSize={TxtDivTxtFontSize} textRight={`${evPointBalance} P`} textRightFontSize={TxtDivTxtFontSize} />
          </Box>

          <Box h={'1.5rem'}/>

          <NotoSansExtraBoldH2 fontSize={'5vw'}>Your Bonvoy Membership</NotoSansExtraBoldH2>
          <Box margin={'3%'}>
            <TxtDividerTxt textLeft='Membership' textLeftFontSize={TxtDivTxtFontSize} textRight={`${bonvoyMembership}`} textRightFontSize={TxtDivTxtFontSize} />
          </Box>
          <BlurBox>
            <Box padding={'0.5rem 1rem'}>
              <TimesNewRomItalic fontSize={'1rem'}>{bonvoyMembership}</TimesNewRomItalic>
              <WhiteUl fontFamily={'Helvetica'} fontSize={'0.8rem'}>
                {discount_rate && <li>{`Discount ${discount_rate}% on every EV charging bill`}</li>}
              </WhiteUl>
            </Box>
          </BlurBox>
        </HomePageMarginBox>
      </Box>
    </>
  );
};

Home.propTypes = {

};

export default Home;