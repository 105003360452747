import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Text } from '@chakra-ui/react';
import BonvoyMyPromotionsButton from 'components/button/BonvoyMyPromotionsButton';
import { useNavigate } from 'react-router-dom';

const MyPromotionsCustom = props => {
  const { currPromoCnt } = props;
  const navigate = useNavigate();
  return (
    <>
      <Box
        w='100%' backgroundColor='rgba(30 30 30 / 1)'
        marginBottom='3%'
      >
        <Center w='100%' h='100%'>
          <BonvoyMyPromotionsButton onClick={(e) => navigate('my-promotions')} />
        </Center>
      </Box>
    </>
  );
};

MyPromotionsCustom.propTypes = {
  currPromoCnt: PropTypes.number
};

export default MyPromotionsCustom;