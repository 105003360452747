import create from 'zustand';
import axios from 'axios';


export const useAccountChargingStore = create((set) => ({
  member_id: "",
  name: "",
  car_number: "", // 추가 예정
  connector_status: "", // "Charging"/"Available", null
  charging_cp_id: "", // "id string", null
  charging_connector_id: null, // "connector id", null
  fetchAccountChargingStatus: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/account/info`;
    const reqBody = {
      member_id: payload?.member_id
    }
    const data = await (await axios.post(url, reqBody)).data;
    set(state => ({
      member_id: data.result?.member_id,
      name: data.result?.name,
      car_number: data.result?.car_number, // 추가 예정
      connector_status: data.result?.connector_status,
      charging_cp_id: data.result?.charging_cp_id,
      charging_connector_id: data.result?.charging_connector_id
    }))
  }
}));

// 충전 시작/종료 제어
export const useControlChargingStore = create((set) => ({
  // 100-success, 1000-fail
  code: '',
  message: '',
  controlCharging: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/control/charging`;
    const reqBody = {
      member_id: payload?.member_id,
      cp_id: payload?.cp_id,
      connector_id: payload?.connector_id,
      command: payload?.command // "START", "CANCEL"
    };
    const data = await (await axios.post(url, reqBody)).data;
    set(state => ({
      code: data?.status?.code,
      message: data?.status?.message
    }))

    return data;
  }
}));

// 충전중 상태 조회
export const useGetChargingStatus = create((set) => ({
  cp_id: '',
  connector_id: null,
  connector_pk: '',
  connector_type: '',
  cost: null,
  energy: null,
  soc: null, // 현재 배터리 충전량
  remain_time: null,
  fetchChargingStatus: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/charging/status`;
    const reqBody = {
      member_id: payload?.member_id,
      cp_id: payload?.cp_id,
      connector_id: payload?.connector_id
    };
    const data = await (await axios.post(url, reqBody)).data;
    set(state => ({
      cp_id: data.result?.cp_id,
      connector_id: data.result?.connector_id,
      connector_pk: data.result?.connector_pk,
      connector_type: data.result?.connector_type,
      cost: data.result?.cost,
      energy: data.result?.energy,
      soc: data.result?.soc, // 현재 배터리 충전량
      remain_time: data.result?.remain_time
    }))

    return data;
  }
}));

export const useGetBillInfo = create((set) => ({
  charge_start_time: '',
  charge_end_time: '',
  amount: '',
  price: '',
  currency: '',
  fetchBillLatest: async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/open-api/get/charge/info`;
    const reqBody = {
      cp_id: payload?.cp_id,
      connector_id: payload.connector_id
    };
    const data = await (await axios.post(url, reqBody)).data;
    set(state => ({
      charge_start_time: data?.result?.charge_start_time,
      charge_end_time: data?.result?.charge_end_time,
      amount: data?.result?.amount,
      price: data?.result?.price,
      currency: data?.result?.currency,
    }))

    return data;
  }
}))