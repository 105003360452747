import React from 'react';
import { Navigate } from 'react-router-dom';

import { User, useAuthStore } from 'stores/auth/authStore';

import { getCookie } from 'util/cookie';

/**
 * Children requires authentication.
 * If the user is unauthenticated, routes to '/login'
 */
const AuthRoute = props => {
  const { user, setUser } = useAuthStore((state) => ({ user: state.user, setUser: state.setUser }));

  const authCheck = () => {
    const userId = getCookie('userId');

    return !!userId;
  }

  // no authCookie exist. Need to login!
  if (!authCheck()) {
    return <Navigate to="/login" replace />
  }
  if (getCookie('userId') !== user?.userId) {
    // sync user to cookie
    setUser(new User({ userId: getCookie('userId'), firstName: getCookie('firstName'), lastName: getCookie('lastName') }))
  }

  return (
    props.children
  );
};

AuthRoute.propTypes = {

};

export default AuthRoute;